<template>
  <div class="">

  </div>
</template>

<script>
import Axios from "axios";
import _ from "lodash";
import {mapGetters} from "vuex";

export default {
  name: "cards-mixins",
  components: {
  },
  data() {
    return {
      reasonForDeclineORM: '',
      locationForDecline: '',
      cardIdForCopy: null,
      cardNumberForCopy: null,
      copyCard: {
        isNew: false,
        copyToCardId: '',
        isCopyImages: false,
        isCopyFiles: false,
        copyToCard: {},
      },
      modal: {},
      searchFilter: '',
      isAutoSave: false,
      card_drag_id: null,
      updateData: {},
      openedNewTabCardId: null,
    }
  },
  methods: {
    openCardInNewTab(cardId) {
      this.closeTableContext()
      this.openedNewTabCardId = cardId;
      this.openNewTabCard();
    },
    openCardInNewWindow(cardId) {
      this.closeTableContext()
      this.openedNewTabCardId = cardId;
      this.openNewBrowserCard();
    },
    openNewBrowserCard() {
      window.open(
          window.location.origin + "/cards/view/" + this.openedNewTabCardId,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=" +
          screen.width +
          ",height=" +
          screen.height
      );
    },
    openNewTabCard() {
      //  console.log(this.openedNewTabCardId);
      window.open(
          window.location.origin + "/cards/view/" + this.openedNewTabCardId,
          "_blank"
      );
    },
    beforeDebouncedAutoSave: function () {
      if (!this.isAutoSave) {
        //    console.log('not isAutoSave in beforeDebouncedAutoSave')
        return;
      }
      this.debouncedAutoSave();
    },
    autoSave() {
      if (!this.isAutoSave) {
        //  console.log('not isAutoSave')
        return;
      }
      let card_id = this.$route.params.card_id || this.card_drag_id;
      Axios({
        method: "post",
        responseType: "json",
        headers: {Autosave: true},
        url: `/fe/card/${card_id}`,
        validateStatus: function (status) {
          return status < 500;
        },
        data: this.updateData,
      })
          .then((response) => {
            if (response.status === 200) {
            } else {
            }
            this.isAutoSave = true;
          })
          .catch((error) => {
            this.isAutoSave = true;
          });
    },
    setUpdateData: function (v) {
      //let uid = Math.random().toString(36).substr(2, 9) + Date.now();
      this.updateData = {};
      this.updateData.uid = v;
      this.beforeDebouncedAutoSave();
    },
    setCardPriority(cardId, priority) {
      this.closeTableContext();
      const index = this.cardsAll.findIndex(card => {
        return Number(card.card_id) === Number(cardId);
      });
      if (index !== -1) {
        const card = this.cardsAll[index];
        card.priority = priority;

        let update = {};
        update.priority = priority;
        this.isAutoSave = true;
        this.card_drag_id = cardId;
        this.setUpdateData(update);
      }else{
        console.log('Not Found CARD in setPriority')
      }
    },
    onSearchFilterChange(filters) {
      console.log(filters, 'filters')
      this.$store.commit('card/setSearchFilterObj', filters)
      if (filters && filters[0] && filters[0].search) {
        this.$store.commit('setCardFilter', filters[0].search);
        // this.filter = filters.search;
      } else {
        this.$store.commit('setCardFilter', '');
        // this.filter = '';
      }
    },
    filterCards(value) {
      return _.filter(value, (itm) => {
        if (!this.isCardNumberFilterActive && !this.isPartNumberFilterActive && !this.isInsurerNameFilterActive
            && !this.isRegoFilterActive && !this.isMakeFilterActive && !this.isModelFilterActive && !this.isEstimateNumberFilterActive && !this.isCustomerNameFilterActive) {
          return true
        }
        if (this.isCardNumberFilterActive) {
          if (_.includes((this.computedCardNumberFilter), _.trim(itm.card_number))) {
            return true
          }
        }

        if (this.isInsurerNameFilterActive && _.trim(itm.insurance)) {
          if (_.includes((this.computedInsurerNameFilter), _.trim(itm.insurance))) {
            return true
          }
        }

        if (this.isRegoFilterActive && _.trim(itm.rego_number)) {
          if (_.includes((this.computedRegoFilter), _.trim(itm.rego_number))) {
            return true
          }
        }

        if (this.isMakeFilterActive && _.trim(itm.make)) {
          if (_.includes((this.computedMakeFilter), _.trim(itm.make))) {
            return true
          }
        }
        if (this.isModelFilterActive && _.trim(itm.model)) {
          if (_.includes((this.computedModelFilter), _.trim(itm.model))) {
            return true
          }
        }
        if (this.isPartNumberFilterActive && itm.partNumbers) {
          let isCheckedPartNumber = _.some(itm.partNumbers.split(' '), (n) => {
            return n && _.includes(this.computedPartNumberFilter, n)
          })
          if (isCheckedPartNumber) {
            return true
          }
        }

        if (this.isEstimateNumberFilterActive && _.trim(itm.estimateNumber)) {
          if (_.includes((this.computedEstimateNumberFilter), _.trim(itm.estimateNumber))) {
            return true
          }
        }

        if (this.isCustomerNameFilterActive && _.trim(itm.customer_name)) {
          if (_.includes((this.computedCustomerNameFilter), _.trim(itm.customer_name))) {
            return true
          }
        }
        return false
      })
    },
    getIsActiveFilter(field) {
      for (let key in this.getterSearchFilterObj) {
        if (this.getterSearchFilterObj[key][field]) {
          return true
        }
      }
      return false
    },
    getActiveFilter(field) {
      let filter = {            }
      filter[field] = ''
      let obj = Object.assign({}, this.getterSearchFilterObj)
      for (let key in obj) {
        if (obj[key][field]) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter[field], ';')
      return _.filter(filter, (f) => {return _.trim(f)})
    },
    assignedToPerson(data, user) {
      if (data && data.item && data.item.card_id > 0) {
        let card_id = data.item.card_id;
        let uid = Math.random().toString(36).substr(2, 9) + Date.now();
        let update = {};
        update[uid] = {};
        update[uid].assignedTo = {};
        update[uid].assignedTo.id = user;
        Axios({
          method: "post",
          responseType: "json",
          headers: {Autosave: true},
          url: `/fe/card/${card_id}`,
          validateStatus: function (status) {
            return status < 500;
          },
          data: update,
        })
            .then((response) => {
              if (response.status === 200) {
              }
            })
            .catch((error) => {
            });
      }
      this.$refs.rightClickMenuForCards.close();
    },
    completeMarkAs: function () {
      this.$refs.markAsModal.hide();
      let update = {};
      update.state = this.modal.type;
      this.setUpdateData(update);
      var card_id = this.modal.card_id;
      Axios({
        method: "post",
        responseType: "json",
        headers: {Autosave: true},
        url: `/fe/card/${card_id}`,
        validateStatus: function (status) {
          return status < 500;
        },
        data: this.updateData,
      }).then((response) => {
        if (response.status === 200) {
        }
      });
    },
    onRightClickByMenu(type, data) {
      this.closeTableContext();
      if (!this.isStatusActive) {
        return
      }
      var card_id = data.item.card_id;
      Axios.get(`/fe/card/${card_id}`).then((response) => {
        var card = response.data.card;
        if (card && card.estimate_number != "") {
          if (type == 1) {
            this.modal = {
              type: type,
              card_id: card_id,
              title: "Card - Mark As Active",
              text:
                  "We have found the following Estimates linked to this card. These will be marked as Active.",
              estimate_number: card.estimate_number,
              supplement_numbers: card.supplement_numbers,
            };
          } else if (type == 0) {
            this.modal = {
              type: type,
              card_id: card_id,
              title: "Card - Mark As Open",
              text:
                  "We have found the following Estimates linked to this card. These will be marked as Open.",
              estimate_number: card.estimate_number,
              supplement_numbers: card.supplement_numbers,
            };
          } else if (type == 3) {
            this.modal = {
              type: type,
              card_id: card_id,
              title: "Card - Mark As Closed",
              text:
                  "We have found the following Estimates linked to this card. These will be marked as Closed.",
              estimate_number: card.estimate_number,
              supplement_numbers: card.supplement_numbers,
            };
          }
          this.$refs.markAsModal.show();
        } else {
          this.modal = {
            type: type,
            card_id: card_id,
          };
          this.completeMarkAs();
        }
      });
    },
    closeTableContext() {
      this.$nextTick(()=>{
        //   console.log('nextTick.closeTableContext')
        if (this.$refs.rightClickMenuForCards) {
          //   console.log('closeTableContext2 close')
          this.$refs.rightClickMenuForCards.close();
        }
      })
    },
    addToDepartureBoard(id, number) {
      this.closeTableContext();
      NProgress.start();
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/board/departure/add-card`,
        validateStatus: function (status) {
          return status < 500;
        },
        data: {
          cardId: id,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          toastr.success('Card ' + number + ' added successfully to the Departure Board');
        } else {
          toastr.error(response.data.msg);
        }
      })
          .catch(error => {
            toastr.error(error);
          })
          .finally(() => {
            NProgress.done();
          });
    },
    addToVehicleInBoard(id, number) {
      this.closeTableContext();
      NProgress.start();
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/board/in/add-card`,
        validateStatus: function (status) {
          return status < 500;
        },
        data: {
          cardId: id,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          toastr.success('Card ' + number + ' added successfully to the Vehicle In Board');
        } else {
          toastr.error(response.data.msg);
        }
      })
          .catch(error => {
            toastr.error(error);
          })
          .finally(() => {
            NProgress.done();
          });
    },
    markAsRead: function (cardId) {
      this.closeTableContext();
      this.$store.dispatch('markAsReadCardNotification', cardId);
      let item = {};
      item = _.find(this.cards, {'card_id': cardId});
      if (item && item.activity) {
        item.activity.Assignment = {};
        item.activity.Email = {};
        if (item.filters) {
          item.filters = '';
        }
      }
      //
      // item = _.find(this.cardsAll, {'card_id': cardId});
      // if (item && item.activity) {
      //   item.activity.Assignment = {};
      //   item.activity.Email = {};
      //   if (item.filters) {
      //     item.filters = '';
      //   }
      // }

      // item = _.find(this.cardsOpen, {'card_id': cardId});
      // if (item && item.activity) {
      //   item.activity.Assignment = {};
      //   item.activity.Email = {};
      //   if (item.filters) {
      //     item.filters = '';
      //   }
      // }
      //
      // item = _.find(this.cardsActive, {'card_id': cardId});
      // if (item && item.activity) {
      //   item.activity.Assignment = {};
      //   item.activity.Email = {};
      //   if (item.filters) {
      //     item.filters = '';
      //   }
      // }
      //
      // item = _.find(this.cardsClosed, {'card_id': cardId});
      // if (item && item.activity) {
      //   item.activity.Assignment = {};
      //   item.activity.Email = {};
      //   if (item.filters) {
      //     item.filters = '';
      //   }
      // }
    },
    acceptORMJob () {
      this.closeTableContext();
      NProgress.start();
      Axios.post("/fe/testAccept", {})
          .then(response => {
            if (response?.data?._status) {
              toastr.success('ORM job [ORMnumber] has been successfully Accepted.');
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            NProgress.done();
          })
    },
    copyCardModal: function (cardId, cardNumber) {
      this.closeTableContext();
      if (!this.isStatusActive) {
        return
      }
      this.cardIdForCopy = cardId;
      this.cardNumberForCopy = cardNumber;
      this.copyCard.isNew = false;
      this.copyCard.copyToCardId = 0;
      this.copyCard.copyToCard = {};
      this.copyCard.isCopyImages = false;
      this.copyCard.isCopyFiles = false;
      this.$refs.copyCardModal.show();
    },
    completeCopyCard: function () {
      this.$refs.copyCardModal.hide();
      NProgress.start();
      if (this.copyCard.copyToCard && this.copyCard.copyToCard.card_id && this.copyCard.copyToCard.card_id > 0) {
        this.copyCard.copyToCardId = this.copyCard.copyToCard.card_id;
      }
      delete this.copyCard.copyToCard;
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/card/${this.cardIdForCopy}/copy-card`,
        validateStatus: function (status) {
          return status < 500;
        },
        data: this.copyCard,
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          if (!this.copyCard.isNew) {
            this.$store.dispatch('card/deleteCardById', this.copyCard.copyToCardId);
          }
          toastr.success('Duplicate Card ' + response.data.number + ' created successfully');
          this.$router.push({
            name: 'CardsEdit',
            params: {
              card_id: response.data.id,
              filters: this.$route.params.filters ? this.$route.params.filters : this.$route.query.filters,
              trackerFilter: this.$route.params.trackerFilter ? this.$route.params.trackerFilter : this.$route.query.trackerFilter
            }
          });
          return;
        } else {
          toastr.error(response.data.msg);
        }
      })
          .catch(error => {
            toastr.error(error);
          })
          .finally(() => {
            NProgress.done();
          });
    },
    openDeclineModal() {
      this.$refs.declineORMJob.show()
    },
    declineORMJob () {
      this.$refs.declineORMJob.hide();
      NProgress.start();
      Axios.post("/fe/testDecline", {})
          .then(response => {
            if (response?.data?._status) {
              toastr.success('ORM job [TestOrmNumber] has been successfully declined.')
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            NProgress.done();
          })
    },
  },
  created() {
    this.debouncedAutoSave = _.debounce(this.autoSave, 1000);
  },
  computed: {
    ...mapGetters({
      cardsAll: 'card/getAllCards',
      isStatusActive: 'isStatusActive',
      getterSearchFilterObj: 'card/getSearchFilterObj',

      isShopManagerUser: 'isShopManagerUser',
      isProductionManagerUser: 'isProductionManagerUser',
      isEstimatorUser: 'isEstimatorUser',
      isPDRTechnicianUser: 'isPDRTechnicianUser',
      isDisplayBoardUser: 'isDisplayBoardUser',
      isTechnicalUser: 'isTechnicalUser',
      isPanelTechnicianUser: 'isPanelTechnicianUser',
      isPaintTechnicianUser: 'isPaintTechnicianUser',
      isCustomerUser: 'isCustomerUser',
      isStripperFitterUser: 'isStripperFitterUser',

      getterToken: 'card/getToken',
      getterTokens: 'card/getTokens',

      ceStatuses: 'ceStatuses',
    }),
    isCanSearchBySomeFields() {
      return !this.isPDRTechnicianUser && !this.isPanelTechnicianUser && !this.isPaintTechnicianUser && !this.isStripperFitterUser && !this.isDisplayBoardUser
    },
    filter: {
      get() {
        return this.$store.getters.cardFilter;
      },
      set(value) {
        this.$store.commit('setCardFilter', value);
      }
    },
    computedSearchFilters() {
      let cardNumbers = {};
      let estimateNumbers = {};
      let partNumbers = {};
      let insurers = {};
      let regos = {};
      let makes = {};
      let models = {};
      let vehicleOwner = {};

      this.cardsAll.forEach(card => {
        if (card.card_number) {
          cardNumbers[card.card_number.trim().toLowerCase()] = {label: card.card_number.trim()}
        }
      })

      this.cardsAll.forEach(card => {
        if (card.customer_name) {
          vehicleOwner[card.customer_name.trim().toLowerCase()] = {label: card.customer_name.trim()}
        }
      })

      this.cardsAll.forEach(card => {
        if (card.estimateNumber) {
          estimateNumbers[card.estimateNumber.trim().toLowerCase()] = {label: card.estimateNumber.trim()}
        }
      })

      this.cardsAll.forEach(card => {
        if (card.rego_number) {
          regos[card.rego_number.trim().toLowerCase()] = {label: card.rego_number.trim()}
        }
      })

      this.cardsAll.forEach(card => {
        if (card.make) {
          makes[card.make.trim().toLowerCase()] = {label: card.make.trim()}
        }
      })

      this.cardsAll.forEach(card => {
        if (card.model) {
          models[card.model.trim().toLowerCase()] = {label: card.model.trim()}
        }
      })

      this.cardsAll.forEach(card => {
        if (card.insurance) {
          insurers[card.insurance.trim().toLowerCase()] = {label: card.insurance.trim()}
        }
      })

      this.cardsAll.forEach(card => {
        if (card.partNumbers) {
          let numbers = card.partNumbers.split(' ')
          _.forEach(numbers, n => {
            if (_.trim(n)) {
              partNumbers[n.toLowerCase().trim()] = {label: n.toLowerCase().trim()}
            }
          })
        }
      })

      partNumbers = _.orderBy(Object.values(partNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      cardNumbers = _.orderBy(Object.values(cardNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      vehicleOwner = _.orderBy(Object.values(vehicleOwner), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      estimateNumbers = _.orderBy(Object.values(estimateNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      regos = _.orderBy(Object.values(regos), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      makes = _.orderBy(Object.values(makes), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      models = _.orderBy(Object.values(models), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      insurers = _.orderBy(Object.values(insurers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])

      let result = [
        {
          id: 'cardNumber',
          label: 'Card Number',
          iconClass: 'bx-user',
          options: [
            cardNumbers,
          ]
        },
        {
          id: 'estimateNumber',
          label: 'Estimate Number',
          iconClass: 'bx-user',
          options: [
            estimateNumbers,
          ]
        },
        {
          id: 'partNumber',
          label: 'Part Number',
          iconClass: 'bx-user',
          options: [
            partNumbers,
          ]
        },
        {
          id: 'insurer',
          label: 'Insurer',
          iconClass: 'bx-user',
          options: [
            insurers,
          ]
        },
        {
          id: 'rego',
          label: 'Rego',
          iconClass: 'bx-user',
          options: [
            regos,
          ]
        },
        {
          id: 'make',
          label: 'Make',
          iconClass: 'bx-user',
          options: [
            makes,
          ]
        },
        {
          id: 'model',
          label: 'Model',
          iconClass: 'bx-store',
          options: [
            models
          ]
        },
        {
          id: 'customerName',
          label: 'Vehicle Owner',
          iconClass: 'bx-store',
          options: [
            vehicleOwner
          ]
        },
      ]
      if (!this.isCanSearchBySomeFields) {
        result = _.filter(result, (r) => {
          return r.id !== 'estimateNumber' && r.id !== 'insurer'
        })
      }
      return result
    },
    isDisabledModalFinishButton () {
      return !!(_.isEmpty(this.reasonForDeclineORM) || _.isEmpty(this.locationForDecline));
    },
    computedCardsForCopyCard() {
      let vm = this;
      let cards = _.filter(this.cardsAll, function (i) {
        return !(i.state === "3" || i.status_name == "Invoiced" || i.status_name == "Closed");
      });
      return cards;
    },

    isHasNotFilters() {
      return !this.isCardNumberFilterActive && !this.isPartNumberFilterActive && !this.isInsurerNameFilterActive
          && !this.isRegoFilterActive && !this.isMakeFilterActive && !this.isModelFilterActive && !this.isEstimateNumberFilterActive && !this.isCustomerNameFilterActive
    },

    isCardNumberFilterActive() {
      return this.getIsActiveFilter('cardNumber')
    },
    computedCardNumberFilter() {
      return this.getActiveFilter('cardNumber')
    },
    isPartNumberFilterActive() {
      return this.getIsActiveFilter('partNumber')
    },
    computedPartNumberFilter() {
      return this.getActiveFilter('partNumber')
    },

    isEstimateNumberFilterActive() {
      return this.getIsActiveFilter('estimateNumber')
    },
    computedEstimateNumberFilter() {
      return this.getActiveFilter('estimateNumber')
    },

    isCustomerNameFilterActive() {
      return this.getIsActiveFilter('customerName')
    },
    computedCustomerNameFilter() {
      return this.getActiveFilter('customerName')
    },

    isInsurerNameFilterActive() {
      return this.getIsActiveFilter('insurer')
    },
    computedInsurerNameFilter() {
      return this.getActiveFilter('insurer')
    },

    isRegoFilterActive() {
      return this.getIsActiveFilter('rego')
    },
    computedRegoFilter() {
      return this.getActiveFilter('rego')
    },

    isMakeFilterActive() {
      return this.getIsActiveFilter('make')
    },
    computedMakeFilter() {
      return this.getActiveFilter('make')
    },

    isModelFilterActive() {
      return this.getIsActiveFilter('model')
    },
    computedModelFilter() {
      return this.getActiveFilter('model')
    },
  }
}
</script>

<style scoped>

</style>
