<template>
    <div class="board">
        <div class="page-header">
            <h4>Vehicle In Board</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">View Boards</li>
                <li class="breadcrumb-item active">Vehicle In Board</li>
            </ol>
            <h4 v-if="isShowBusinessName" class="header-display-name">{{ getterBusinessName }}</h4>
        </div>

        <div class="box-block bg-white cards boards">
            <tabs
                    ref="boards-vehicle-in-table"
            >
                <tab
                        name="All"
                        id="boards-vehicle-in-all"
                        :class-name="'tab-content-table'"
                >
                    <div class="pagination-block-bottom" style="overflow-x: auto;">
                        <b-table
                                responsive
                                hover
                                striped
                                class="noselect rs-scroll rs-scroll--y"
                                ref="boards"
                                :items="vehicleInBoards"
                                :fields="fields"
                                :filter="filter"
                                :filter-function="filterForTable"
                                :current-page="boardAllCurrentPage"
                                :per-page="vehiclesPerPage"
                                @filtered="onAllCardsFiltered"
                                @row-contextmenu="openMenuCards2">
                            <template v-slot:cell(progress)="data">
                                <div class="item-progress-container">
                                    <div class="item-progress">
                                        <div class="item-progress__text">Parts</div>
                                        <div class="item-progress__text item-progress__text--blue" v-if="data.item.progress.parts == 100">{{data.item.progress.parts}}%
                                        </div>
                                        <div class="item-progress__text item-progress__text--yellow" v-else-if="data.item.progress.parts !== null && data.item.progress.parts != 0">
                                            {{data.item.progress.parts}}%
                                        </div>
                                        <div v-else-if="data.item.progress.parts === null" class="item-progress__text item-progress__text--grey">-</div>
                                        <div class="item-progress__text item-progress__text--grey" v-else>0%</div>
                                    </div>
                                    <div class="item-progress">
                                        <div class="item-progress__text item-progress__text--blue">RR</div>
                                        <div class="item-progress__text item-progress__text--blue" v-if="data.item.progress.rr == 100">{{data.item.progress.rr}}%
                                        </div>
                                        <div class="item-progress__text item-progress__text--yellow" v-else-if="data.item.progress.rr !== null && data.item.progress.rr != 0">{{data.item.progress.rr}}%
                                        </div>
                                        <div v-else-if="data.item.progress.rr === null" class="item-progress__text item-progress__text--grey">-</div>
                                        <div class="item-progress__text item-progress__text--grey" v-else>0%</div>
                                    </div>
                                    <div class="item-progress">
                                        <div class="item-progress__text item-progress__text--blue">Repair</div>
                                        <div class="item-progress__text item-progress__text--blue" v-if="data.item.progress.repair == 100">{{data.item.progress.repair}}%
                                        </div>
                                        <div class="item-progress__text item-progress__text--yellow" v-else-if="data.item.progress.repair !== null && data.item.progress.repair != 0">
                                            {{data.item.progress.repair}}%
                                        </div>
                                        <div v-else-if="data.item.progress.repair === null" class="item-progress__text item-progress__text--grey">-</div>
                                        <div class="item-progress__text item-progress__text--grey" v-else>0%</div>
                                    </div>
                                    <div class="item-progress">
                                        <div class="item-progress__text item-progress__text--blue">Paint</div>
                                        <div class="item-progress__text item-progress__text--blue" v-if="data.item.progress.paint == 100">{{data.item.progress.paint}}%
                                        </div>
                                        <div class="item-progress__text item-progress__text--yellow" v-else-if="data.item.progress.paint !== null && data.item.progress.paint != 0">
                                            {{data.item.progress.paint}}%
                                        </div>
                                        <div v-else-if="data.item.progress.paint === null" class="item-progress__text item-progress__text--grey">-</div>
                                        <div class="item-progress__text item-progress__text--grey" v-else>0%</div>
                                    </div>
                                    <div class="item-progress">
                                        <div class="item-progress__text">Sublet</div>
                                        <div class="item-progress__text item-progress__text--blue" v-if="data.item.progress.sublet == 100">{{data.item.progress.sublet}}%
                                        </div>
                                        <div class="item-progress__text item-progress__text--yellow" v-else-if="data.item.progress.sublet !== null && data.item.progress.sublet != 0">
                                            {{data.item.progress.sublet}}%
                                        </div>
                                        <div v-else-if="data.item.progress.sublet === null" class="item-progress__text item-progress__text--grey">-</div>
                                        <div class="item-progress__text item-progress__text--grey" v-else>0%</div>
                                    </div>
                                    <div class="item-progress">
                                        <div class="item-progress__text">Other</div>
                                        <div class="item-progress__text item-progress__text--blue" v-if="data.item.progress.other == 100">{{data.item.progress.other}}%</div>
                                        <div class="item-progress__text item-progress__text--yellow" v-else-if="data.item.progress.other !== null && data.item.progress.other != 0">
                                          {{data.item.progress.other}}%
                                        </div>
                                        <div v-else-if="data.item.progress.other === null" class="item-progress__text item-progress__text--grey">-</div>
                                        <div class="item-progress__text item-progress__text--grey" v-else>0%</div>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:cell(status)="data">
                              <div class="">

                              </div>
                            </template>

                            <template v-slot:cell(images)="data">
                                <div class="vehicle-image-container">
                                    <div class="vehicle-image border-image" v-if="data.item.images.length != 0" :key="'departure-board-image-'+data.item.images[0].id">
                                        <img
                                                :src="getSrc(data.item.images[0].src)"
                                                @contextmenu.prevent="openMenuForImgs($event, data.item.id, data.item.images[0].id)"
                                                alt=""/>
                                    </div>
                                </div>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                        :role="'cards'"
                        :count="vehicleInBoards.length"
                        :countFiltered="filter ? boardInFiltered : vehicleInBoards.length"
                        :currentPage="boardAllCurrentPage"
                        @changedCurrentPage="value => boardAllCurrentPage = value"
                        :perPage="vehiclesPerPage"
                        @changedPerPage="value => vehiclesPerPage = value"
                        >
                    </block-pagination>
                </tab>
                <template slot="nav-item-right">
                    <div class="tabs-nav-right-search">
                        <SearchFilter ref="search-filter" placeholder="Search or Filter results" v-model="filter"/>
                    </div>

                    <div class="tabs-nav-right-options">
                        <div class="navbar-nav nav dropdown dropdown-options  show inline">
                            <button type="button"
                                    class="btn waves-effect waves-light ex-options-button"
                                    id="dropdownMenuLink"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="true">
                                Options
                                <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                                        <i class="fa fa-chevron-down pull-right"></i>
                                                    </span>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
                                                     aria-labelledby="dropdownMenuLink">
                                                    <div @click.prevent class="dropdown-item text-primary p-1">Zoom: {{computedZoomScale}}</div>
                                                    <a @click.prevent="zoom((zoomScale += 0.1).toFixed(1))" class="dropdown-item text-primary p-1 clickable">Zoom +</a>
                                                    <a @click.prevent="zoom((zoomScale -= 0.1).toFixed(1))" class="dropdown-item text-primary p-1 clickable">Zoom -</a>
                                                    <a @click.prevent="setDefaultZoomScale" class="dropdown-item text-primary p-1 clickable">Set As Default</a>
                                                    <a @click.prevent="zoomReset(1.4)" class="dropdown-item text-primary p-1 clickable">Reset</a>
                                                </div>
                                            </div>
                                        </div>
                </template>
            </tabs>
        </div>
        <vue-context ref="rightClickMenuForBoards" @open="contextOpen" @close='contextClose'>
            <ul slot-scope="scope">
                <li v-if="scope && scope.data && scope.data.item && scope.data.item.id" @click="removeVehicle(scope.data.item.id, scope.data.item.cardNumber, scope.data.index)">
                    Remove Vehicle
                </li>
              <li class="hasSubContext" v-if="scope && scope.data && scope.data.item && scope.data.item.id && !isCustomerUser">
                Add to
                <i class="icon-right bx bx-chevron-right"></i>
                <ul class="context-level subContext">
                  <li @click.prevent="addedToDepartureBoard(scope.data.item.id, scope.data.item.cardNumber)">Departure Board</li>
                </ul>
              </li>
            </ul>
        </vue-context>
        <vue-context ref="rightClickMenuForImgOfBoards">
            <ul slot-scope="scope">
                <li v-if="scope && scope.data && scope.data.imageId" @click="removeImg(scope.data.boardId, scope.data.imageId)">
                    Remove Image
                </li>
                <li class="hasSubContext" v-if="scope && scope.data && scope.data.imageId && scope.data.boardId && !isCustomerUser">
                  Add to
                  <i class="icon-right bx bx-chevron-right"></i>
                  <ul class="context-level subContext context-level-image">
                    <li @click.prevent="addedImageToDepartureBoard(scope.data.boardId, scope.data.imageId)">Departure Board</li>
                  </ul>
                </li>
            </ul>
        </vue-context>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import SearchFilter from '../search-filter/search-filter';
    import _ from "lodash";
    import Axios from "axios";
    import VueContext from "../../extends/vue-context";
    import BlockPagination from '../utility/block-pagination';
    import {appConfig} from "../../config";

    const tableMixin = {
        data() {
            return {
                clientWidth: 0,
                filter: '',
                contextOpenEvent: null,
                boardAllCurrentPage: 1,
                vehiclesPerPage: 35,
                boardInFiltered: 0,
                fields: [
                    {
                        label: "Card Nbr",
                        key: "cardNumber",
                        sortable: true,
                        tdClass: "text-center  cards-table-number",
                        thClass: "text-center bg-navyblue  cards-table-number",
                    },
                    {
                        label: "Rego",
                        key: "rego",
                        sortable: true,
                        tdClass: "text-center cards-table-rego",
                        thClass: "text-center bg-navyblue cards-table-rego",
                    },
                    {
                        label: "Make",
                        key: "make",
                        sortable: true,
                        tdClass: "text-center cards-table-make",
                        thClass: "text-center bg-navyblue cards-table-make",
                    },
                    {
                        label: "Model",
                        key: "model",
                        sortable: true,
                        tdClass: "text-center cards-table-model",
                        thClass: "text-center bg-navyblue cards-table-model",
                    },
                    {
                        label: "Colour",
                        key: "color",
                        sortable: true,
                        tdClass: "text-center cards-table-colour",
                        thClass: "text-center bg-navyblue cards-table-colour",
                    },
                    {
                        label: "Work Item Progress",
                        key: "progress",
                        sortable: true,
                        tdClass: "text-center cards-table-progress",
                        thClass: "text-center bg-navyblue cards-table-progress",
                    },
                    {
                        label: "Assigned",
                        key: "assignedTo",
                        sortable: true,
                        tdClass: "text-center  cards-table-assigned-to",
                        thClass: "text-center bg-navyblue  cards-table-assigned-to",
                    },
                    {
                        label: "Status",
                        key: "status",
                        sortable: true,
                        tdClass: "text-center  cards-table-status",
                        thClass: "text-center bg-navyblue cards-table-status",
                    },
                    {
                        label: "Vehicle Images",
                        key: "images",
                        sortable: true,
                        tdClass: "text-center  cards-table-image",
                        thClass: "text-center bg-navyblue cards-table-image",
                    },
                ],
            };
        },
        mounted() {
            this.setCompactSidebar();
          this.setTitle()
        },
        computed: {
            ...mapGetters({
                getterBoardIn: 'board/getBoardIn',
                getterBoardDeparture: 'board/getBoardDeparture',
                getterZoomScale: 'board/getZoomScaleIn',
                isDisplayBoardUser: 'isDisplayBoardUser',
                isCustomerUser: 'isCustomerUser',
                isStatusActive: 'isStatusActive',
                getterBusinessName : 'getBusinessName',
              isShowBusinessName: 'isShowBusinessName',
            }),
            computedZoomScale() {
                return this.zoomScale.toFixed(1);
            },
            vehicleInBoards() {
                return _.slice(this.getterBoardIn);
            },
        },
        created() {
            this.setCompactSidebar();
            window.addEventListener('resize', this.updateWidth);
            this.updateWidth();
        },
      watch: {
        getterZoomScale(value){
          this.zoomScale = value
          this.zoom(value)
        }
      },
        methods: {
          getSrc(src) {
            if (!_.includes(src, 'at=')) {
              return src + '/?at=' + localStorage.getItem('token')
            }
            return src
          },
            getDepartureId(vehicleId) {
              let vehicleItem = _.filter(this.getterBoardIn, function(item) {
                return item.id == vehicleId
              })
              console.log(vehicleItem, 'vehicle')
              let cardId = vehicleItem[0].cardId
              let departureItem = _.filter(this.getterBoardDeparture, function(item) {
                return item.cardId == cardId
              })
              return departureItem[0].id
            },
            addedImageToDepartureBoard(boardId, imageId) {
              Axios({
                method: 'post',
                responseType: 'json',
                url: `/fe/board/add-image`,
                validateStatus: function (status) {
                  return status < 500;
                },
                data: {
                  boardId: this.getDepartureId(boardId),
                  imageId: imageId,
                },
              }).then(response => {
                if (response.status === 200 && response.data._status) {
                  toastr.success('Image added successfully to the Departure Board');
                } else {
                  //toastr.error('Image has already been added to the Departure Board');
                }
              })
                .catch(error => {
                  //toastr.error(error);
                })
                .finally(() => {
                  NProgress.done();
                });
            },
            setDefaultZoomScale(){
              NProgress.start();
              Axios({
                method: 'post',
                responseType: 'json',
                url: '/fe/board/set-default-zoom',
                validateStatus: function (status) {
                  return status < 500;
                },
                data: {
                  'zoomBoardIn': this.computedZoomScale
                },
              }).then(response => {
                if (response.status === 200 && response.data._status) {
                  this.$store.commit('board/setZoomScaleIn', this.computedZoomScale);
                  toastr.success('Zoom scale has been saved');
                }
              })
                .catch(error => {
                  toastr.error(error);
                })
                .finally(() => {
                  NProgress.done();
                });
            },
            addedToDepartureBoard(boardId, number) {
              NProgress.start();
              Axios({
                method: 'post',
                responseType: 'json',
                url: `/fe/board/departure/add-card`,
                validateStatus: function (status) {
                  return status < 500;
                },
                data: {
                  boardId: boardId,
                },
              }).then(response => {
                if (response.status === 200 && response.data._status) {
                  toastr.success('Card ' + number + ' added successfully to the Departure Board');
                } else {
                  toastr.error('Error!');
                }
              })
                .catch(error => {
                  toastr.error(error);
                })
                .finally(() => {
                  NProgress.done();
                });
            },
            updateWidth() {
                this.clientWidth = window.innerWidth;
            },
            filterForTable(row, filter) {
                let formattedFilter = _.trim(_.toLower(filter))
                if (row.cardNumber && row.cardNumber.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (row.rego && row.rego.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (row.make && row.make.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (row.model && row.model.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (row.color && row.color.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (row.assignedTo && row.assignedTo.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (row.status && row.status.toLowerCase().includes(formattedFilter)) {
                    return true;
                }
            },
            onAllCardsFiltered: function (fi) {
                this.boardInFiltered = fi.length;
                this.boardAllCurrentPage = 1;
            },
            onRowClick: function (item, index, event) {
                this.$router.push("/boards/view/" + item.card_id);
            },
            contextOpen: function (event, w, e) {
                this.contextOpenEvent = event;
            },
            contextClose: function () {
                let event = this.contextOpenEvent;
                if (event) {
                    let row = event.target.parentElement;
                    let rows = row.parentElement.rows;
                    for (let i = 0; i < rows.length; i++) {
                        let elem = rows[i];
                        elem.style.backgroundColor = "";
                    }
                }
            },
            openMenuForImgs(event, boardId, imageId) {
                if (!this.isStatusActive) {
                  return;
                }
                if (this.isDisplayBoardUser) {
                  return
                }
                this.$refs.rightClickMenuForBoards.close();
                this.$refs.rightClickMenuForImgOfBoards.open(event, {imageId: imageId, boardId: boardId});
            },
            openMenuCards2: function (item, index, event) {
                if (this.isDisplayBoardUser) {
                  return
                }
                event.preventDefault();
                if (event.target.localName === 'img') {
                   return;
                }
                //let row = event.target.parentElement;
                //let rows = row.parentElement.rows;
                //if (rows)
                //for (let i = 0; i < rows.length; i++) {
                //    let elem = rows[i];
                //    elem.style.backgroundColor = "";
                //}
                //row.style.backgroundColor = "";
                this.openMenuCards(event, index, item);
            },
            openMenuCards(e, index, item) {
                if (!this.isStatusActive) {
                  return;
                }
                this.$refs.rightClickMenuForImgOfBoards.close();
                this.$refs.rightClickMenuForBoards.open(e, {item: item, index: index});
            },
            removeImg(boardId, imageId) {
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/board/remove-image`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: {
                        boardId: boardId,
                        imageId: imageId,
                    },
                }).then(response => {
                    if (response.status === 200 && response.data._status) {
                        let boardItem = _.filter(this.vehicleInBoards, function (item) {
                            Number(item.id) === Number(boardId);
                        });
                        let index = this.vehicleInBoards.indexOf(boardItem[0]);
                        let indexOfImg = _.filter(this.vehicleInBoards[index].images, function (img) {
                            Number(img.id) === Number(imageId);
                        });
                        this.vehicleInBoards[index].images.splice(indexOfImg, 1);
                        this.$refs.boards.refresh();
                    }
                })
                    .catch(error => {
                        // toastr.error(error);
                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            removeVehicle(id, number, index) {
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/board/remove`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: {
                        boardId: id,
                    },
                }).then(response => {
                    if (response.status === 200 && response.data._status) {
                        //   toastr.success('Card ' + number + ' removed successfully');
                        this.vehicleInBoards.splice(index, 1);
                        this.$refs.boards.refresh();
                        this.contextClose();
                    } else {
                        toastr.error(response.data.msg);
                    }
                })
                    .catch(error => {
                        toastr.error(error);
                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            onAllCardsPageChange: function () {
            },
            setCompactSidebar() {
                //    console.log('COMPACT!!');
                this.$nextTick(() => {
                    document.body.classList.add('compact-sidebar');
                });

            }
        }
    };

    export default {
        name: 'VehicleInBoard',
        components: {
            SearchFilter,
            VueContext,
            BlockPagination,
        },
        mixins: [
            tableMixin
        ],
        data() {
            return {
                title: 'Vehicle In Boards',
                zoomScale: 1.4,
            };
        },
        mounted() {
            this.zoomScale = this.getterZoomScale;
            this.zoom(this.zoomScale);
        },
        unmounted() {
            console.log('unmounted');
            this.zoom(1);
        },
        beforeRouteLeave(to, from, next) {
            console.log('unmounted2');
            document.body.classList.remove('compact-sidebar');
            this.zoom(null);
            next();
            //console.log('beforeRouteLeave', to, from, next)
        },
      beforeDestroy() {
        this.setTitle(true)
      },
      methods: {
        setTitle (isNeedToClear = false) {
          let text = !isNeedToClear ? 'Vehicles In' : appConfig.appName
          let title = document.getElementsByTagName('title')
          if (title?.[0]) {
            title[0].innerText = text
          }
        },
            zoomReset(val){
              this.zoomScale = val
              document.body.style.zoom = val
            },
            zoom(val) {
                console.log('zoom', val);
                document.body.style.zoom = val;
            }
        }
    };
</script>

<style>
    .V3 .boards .tab-content-table table .cards-table-progress {
        width: 30%;
    }

    .V3 .boards .background-white-weekday {
        background-color: white !important;
        padding: 0px !important;
        color: black;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        text-align: left;
        padding-left: 20px !important;
    }

    .V3 .boards .background-white-weekday-td {
      background-color: white !important;
      padding: 0px 0px 12px 0px !important;
      height: 45px;
    }

    .V3 .boards .item-progress__text--yellow {
        color: var(--yellow);
    }

    .V3 .boards .item-progress__text--grey {
        color: var(--grey);
    }

    .V3 .boards .item-progress__text--green {
        color: var(--green);
    }

    .V3 .boards .vehicle-image-container {
        display: grid;
        grid-template-columns: repeat(1, 80px);
        gap: 10px;
        justify-content: center;
    }

    .V3 .boards .vehicle-image {
        position: relative;
        padding-bottom: 100%;
        border-radius: 4px;
        overflow: hidden;
    }

    .V3 .boards .vehicle-image img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }

    .V3 .boards .dropdown-options .btn {
        border-color: rgba(29, 32, 58, 0.25);
        height: 39px;
    }

    .border-image img:hover {
        border: 2px solid red;
        border-radius: 4px;
    }
</style>

<style scoped>
    .tabs-nav-right-search .search-filter >>> .form-control:focus{
      outline: 0 !important;
    }
    .search-filter >>> input:focus{
      background-color: #ebf0fc;
    }
    .v-context .context-level {
        width: 125px;
        left: 139px;
        top: -1px;
    }

    .context-level-image {
        left: -126px !important;
    }
</style>
