<template>
  <div class="card-actions">
    <ul>
      <li>
        <a v-if="item.activity && item.activity.Email && item.activity.Email.date"
           :id="`i-email1-${name}`"
           @click="goToEmail(item)"
           class="notf-icon position-relative clickable">
          <i class="bx bx-envelope active"></i>
          <b-tooltip
              :target="`i-email1-${name}`"
              triggers="hover"
              custom-class="ps-tooltip ps-tooltip-envelope">
              Unread {{ item.activity.Email.smsId ? 'SMS' : 'email' }} message at:<br/>
            <strong>{{ item.activity.Email.date }}</strong>
          </b-tooltip>
        </a>
        <a v-else
           :id="`i-email2-${name}`"
           class="notf-icon position-relative clickable">
          <i class="bx bx-envelope"></i>
        </a>
      </li>
      <li>
        <a :id="`i-external2-${name}`"
           href="javascript:void(0);"
           class="notf-icon">
          <i class="bx bx-link-external"
             :class="item?.activity?.External?.notice ? 'active' : ''"></i>
          <b-tooltip
              v-if="item?.activity?.External?.notice "
              :target="`i-external2-${name}`"
              triggers="hover"
              custom-class="ps-tooltip">
            {{item.activity.External.notice }}
          </b-tooltip>
        </a>
      </li>
      <li>
        <a
            :id="`i-assignment-${name}`"
            href="javascript:void(0);"
            class="notf-icon">
          <i
              class="bx bx-user"
              :class="item.activity &&
                            item.activity.Assignment &&
                            item.activity.Assignment.type
                                ? 'active'
                                : ''
                        "></i>
<!--          <b-tooltip v-if="  item.activity &&  item.activity.Assignment &&-->
<!--                            item.activity.Assignment.type &&-->
<!--                            item.activity.Assignment.type == 'Card'                        "-->
<!--                     :target="`i-task-${name}`"-->
<!--                     triggers="hover"-->
<!--                     custom-class="ps-tooltip">-->
<!--            Card Assigned from:<br/>-->
<!--            <strong>{{ item.activity.Assignment.from }}</strong>-->
<!--          </b-tooltip>-->
<!--          <b-tooltip v-else-if="item.activity &&-->
<!--                            item.activity.Assignment &&-->
<!--                            item.activity.Assignment.type &&-->
<!--                            item.activity.Assignment.type == 'Task'                        "-->
<!--                     :target="`i-task-${name}`"-->
<!--                     triggers="hover"-->
<!--                     custom-class="ps-tooltip"-->
<!--          >-->
<!--            Task Assigned from:<br/>-->
<!--            <strong>{{ item.activity.Assignment.from }}</strong>-->
<!--          </b-tooltip>-->
        </a>
      </li>
      <li>
        <a :id="`i-message2-${name}`" href="javascript:void(0);">
          <i class="bx bx-message-rounded"
             :class="item.activity && item.activity.Message &&  item.activity.Message.date     ? 'active'    : ''                        "></i>
          <b-tooltip
              v-if="item.activity &&
                            item.activity.Message &&
                            item.activity.Message.date                        "
              :target="`i-message2-${name}`"
              triggers="hover"
              custom-class="ps-tooltip ps-tooltip-message">
            Message From:<br/>
            <strong>{{ item.activity.Message.from }}</strong>
          </b-tooltip>
        </a>
      </li>
      <li>
        <a style="padding: 0 2px">
          <div class="card-insurer-colour" :style="{backgroundColor: insurerColor}"></div>
        </a>
      </li>
    </ul>

    <div class="user-avatar" v-if="item.assignedTo && item.assignedTo.id">
            <span class="user-avatar-name"
            >{{
                getInitalForName({
                  id: item.assignedTo.id,
                  name: item.assignedTo.name
                })
              }}
            </span>
      <span class="user-avatar-status">
                <i class="bx bxs-time-five"></i>
            </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: {
        card_id: '',
        activity: {
          Email: {
            date: '',
            id: 0,
            from: '',
            activity_id: 0,
          },
          Assignment: {
            from: '',
            type: ''
          },
          External: {
            date: '',
          },
          Message: {
            from: '',
            date: '',
          },
        },
      }
    },
    // Unique String ID
    // must be unique row and tab
    name: {
      type: String
    },
    insurerColor: {
      type: String,
      default: ''
    },
  },
  methods: {
    getInitalForName ({ id, name }) {
      if (name) {
        const initial = name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g)
        return initial ? initial.join('') : 'NA'
      } else {
        return 'NA'
      }
    },
    goToEmail (item) {
      this.$router.push(
          {
            name: 'CardsEdit',
            params: { action: 'goToEmail', card_id: item.card_id, activityId: item.activity.activityId }
          }
      )
    }
  }
}
</script>

<style>
.card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
  position: relative;
  left: -7px;
  height: 24px;
}

.card-actions ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-actions ul li {
  display: flex;
  align-items: center;
}

.card-actions ul li a {
  padding-left: 4.5px;
  padding-right: 4.5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex-shrink: 0;
  width: 21px;
}

.card-actions ul li:first-child a {
  padding-left: 0;
}

.card-actions ul li i {
  color: #1B1E38;
  font-size: 16px;
  opacity: 0.5;
}

.card-actions ul li + li {
  border-left: 1px solid rgba(28, 31, 57, 0.25);
}

.card-actions i.bx.bx-user {
  font-size: 16px;
  font-weight: bold;
}

.card-actions i.bx.active {
  opacity: 1;
}

.card-actions i.bx.bx-user.active {
  color: #5E79FF;
}

.card-actions i.bx.bx-envelope {
  zoom: 1.1;
}

.card-actions i.bx.bx-envelope.active {
  color: #5E79FF;
}

.card-actions i.bx.bx-link-external {
  /* font-size: 17px; */
  /* opacity: 0.6; */
}

.card-actions i.bx.bx-link-external.active {
  color: #5E79FF;
}

.card-actions i.bx.bx-message-rounded {
  /* font-size: 14px; */
  /* font-weight: bold; */
}

.card-actions i.bx.bx-message-rounded.active {
  color: rgb(255, 167, 114);
}

.user-avatar {
  width: 25px;
  height: 25px;
  background: rgba(22, 25, 50, 0.04);
  border-radius: 50%;
  text-align: center;
  line-height: 27px;
  border: 1px solid rgba(22, 25, 50, 0.1);
  font-size: 9px;
  font-weight: bold;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  top: -1px;
}

.user-avatar-name {
  width: 100%;
  display: block;
}

.user-avatar-status {
  position: relative;
  font-size: 11px;
  top: -20px;
  left: 10px;
  color: #E6D528;
}
</style>

<style scoped>
.card-insurer-colour{
  width: 16px;
  height: 16px;
  border-radius: 3px;
}
.ps-tooltip{
  left: 40px !important;
  width: 180px;
}
.ps-tooltip-envelope{
  left: 60px !important;
}
.ps-tooltip-message{
  left: 70px !important;
}
.ps-tooltip[x-placement="top"] >>> .tooltip-inner{
  border-radius: 10px !important;
}
.ps-tooltip[x-placement="bottom"] >>> .tooltip-inner{
  border-radius: 10px !important;
}
</style>
