<template>
    <div
        class="estimate-preview invoice preview"
        :class="{
          'branding-type1': isVendorBrandingType1,
          'branding-type2': isVendorBrandingType2,
          'branding-type3': isVendorBrandingType3,
        }"
    >
      <table v-if="isVendorBrandingType1" class="table b-table table-preview rs-scroll rs-scroll--y">
        <tr class="nobrd">
          <td colspan="7">
            <div class="preview-logo"><img :src="vendorInfo.logo" style="height: 150px; width: 300px;" alt=""></div>
          </td>
          <td colspan="3">
            <div class="preview-header">
              <div class="preview-header-title">ESTIMATE</div>
              <div class="preview-header-business-name">{{estimateTo.vendor.business_name}}</div>
              <div class="preview-header-item"><span class="preview-header-item-name">ABN:</span> <span class="preview-header-item-value">{{estimateTo.vendor.abn}}</span>
              </div>
              <div class="preview-header-delimiter"></div>
              <div class="preview-header-item"><span class="preview-header-item-name">Estimate Number: </span>
                <span v-if="estimateTo.estimate.supplement_number > 0" class="preview-header-item-value"> {{estimateTo.estimate.estimate_number}} - {{estimateTo.estimate.supplement_number}}</span>
                <span v-else class="preview-header-item-value">{{estimateTo.estimate.estimate_number}}</span>
              </div>
              <div v-if="!isVendorBrandingType1" class="preview-header-item"><span class="preview-header-item-name">Estimator:</span> <span class="preview-header-item-value">{{estimateTo.estimate.estimator}}</span>
              </div>
              <div class="preview-header-item"><span class="preview-header-item-name">Date:</span> <span class="preview-header-item-value">{{estimateTo.estimate.created_on}}</span>
              </div>
              <div class="preview-header-item"><span class="preview-header-item-name">Expiry:</span> <span class="preview-header-item-value">{{estimateTo.estimate.expires_on}}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="!isVendorBrandingType2" class="nobrd">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr  v-if="!isVendorBrandingType2" class="nobrd">
        </tr>
        <tr v-if="!isVendorBrandingType2" class="nobrd">
          <td colspan="4">
            <div class="preview-subhead-name">Customer</div>
          </td>
          <td colspan="6">
            <div class="preview-subhead-name">Vehicle</div>
          </td>
        </tr>
        <tr v-if="!isVendorBrandingType2" class="nobrd">
          <td colspan="4">
            <table>
              <tr class="nobrd">
                <td class="w-25"></td>
                <td class="w-25"></td>
                <td class="w-25"></td>
                <td class="w-25"></td>
              </tr>
              <tr class="nobrd">
                <td>
                  <div class="preview-item-name">Owner Name:</div>
                </td>
                <td colspan="3">
                  <div class="preview-item-value">{{estimateTo.customer.customer_name}}</div>
                </td>
              </tr>
              <tr class="nobrd">
                <td>
                  <div class="preview-item-name">Home Phone:</div>
                </td>
                <td colspan="3">
                  <div class="preview-item-value">{{estimateTo.customer.phone}}</div>
                </td>
              </tr>
              <tr class="nobrd">
                <td>
                  <div class="preview-item-name">Mobile Phone:</div>
                </td>
                <td colspan="3">
                  <div class="preview-item-value">{{estimateTo.customer.mobile}}</div>
                </td>
              </tr>
              <tr class="nobrd">
                <td>
                  <div class="preview-item-name">Email:</div>
                </td>
                <td colspan="3">
                  <div class="preview-item-value">{{estimateTo.customer.email}}</div>
                </td>
              </tr>
              <tr class="nobrd" v-if="estimateTo.customer.customer_type == 'B'">
                <td>
                  <div class="preview-item-name">ABN:</div>
                </td>
                <td colspan="3">
                  <div class="preview-item-value">{{estimateTo.customer.abn}}</div>
                </td>
              </tr>
            </table>
          </td>
          <td colspan="6">
            <table class="nobrd">
              <tr>
                <td class="w-50">
                  <table class="nobrd">
                    <tr>
                      <td class="w-33"></td>
                      <td class="w-33"></td>
                      <td class="w-33"></td>
                    </tr>
                    <tr>
                      <td>
                        <div class="preview-item-name">Make:</div>
                      </td>
                      <td colspan="2">
                        <div class="preview-item-value">{{estimateTo.customer_vehicle.make}}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="preview-item-name">Model:</div>
                      </td>
                      <td colspan="2">
                        <div class="preview-item-value">{{estimateTo.customer_vehicle.model}}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="preview-item-name">Series:</div>
                      </td>
                      <td colspan="2">
                        <div v-if="estimateTo && estimateTo.properties && estimateTo.properties.isHideSeries && estimateTo.properties.isHideSeries === true"
                             class="preview-item-value"> </div>
                        <div v-else class="preview-item-value">{{estimateTo.customer_vehicle.series}}</div>
                      </td>
                    </tr>
                  </table>
                </td>
                <td class="w-50">
                  <table class="nobrd">
                    <tr>
                      <td class="w-33"></td>
                      <td class="w-33"></td>
                      <td class="w-33"></td>
                    </tr>
                    <tr>
                      <td>
                        <div class="preview-item-name">Rego:</div>
                      </td>
                      <td colspan="2">
                        <div class="preview-item-value">{{estimateTo.customer_vehicle.rego_number}}</div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr  class="nobrd">
          <td colspan="10">
            <div class="preview-delimiter"></div>
          </td>
        </tr>
        <!--
         RR with hours
        -->
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.RR.length && estimateTo.line_items.summary['RR-Hrs-SubTotal-Str'] !=''">
          <td colspan="6">
            <div class="preview-subhead-name">R&R</div>
          </td>
          <td>
            <div class="preview-subhead-name">
              <span v-if="isEstimateMethodologyDollar"></span>
              <span v-else>Hrs</span>
            </div>
          </td>
          <td>
            <div class="preview-subhead-name">
              <span v-if="isEstimateMethodologyDollar"></span>
              <span v-else>Rate</span>
            </div>
          </td>
          <td>
            <div class="preview-subhead-name"></div>
          </td>
          <td>
            <div class="preview-subhead-name preview-amount-header">Amount</div>
          </td>
        </tr>
        <tr v-if="!!estimateTo.line_items.lines.RR.length && estimateTo.line_items.summary['RR-Hrs-SubTotal-Str'] !=''"
            v-for="line in estimateTo.line_items.lines.RR">
          <td colspan="6">
            <div class="preview-item-name">{{line.name}}
              <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                Comment: <span>{{line.manual_pdf_comment}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="preview-item-value">
              <span v-if="isEstimateMethodologyDollar"></span>
              <span v-else>{{line.hrs_str}}</span>
            </div>
          </td>
          <td>
            <div class="preview-item-value">
              <span v-if="isEstimateMethodologyDollar"></span>
              <span v-else>{{line.rate_str}}</span>
            </div>
          </td>
          <td>
            <div class="preview-item-value"></div>
          </td>
          <td>
            <div class="preview-item-value preview-amount">{{line.total_str}}</div>
          </td>
        </tr>
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.RR.length && estimateTo.line_items.summary['RR-Hrs-SubTotal-Str'] !=''">
          <td colspan="6">
            <div class="preview-item-name preview-align-right">Subtotal Hrs</div>
          </td>
          <td>
            <div class="preview-item-name">{{estimateTo.line_items.summary["RR-Hrs-SubTotal-Str"]}}</div>
          </td>
          <td>
            <div class="preview-item-value"></div>
          </td>
          <td>
            <div class="preview-item-name preview-align-right">Subtotal</div>
          </td>
          <td>
            <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["RR-Total-SubTotal-Str"]}}</div>
          </td>
        </tr>
        <!--
        RR without hours
       -->
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.RR.length && estimateTo.line_items.summary['RR-Hrs-SubTotal-Str'] ==''">
          <td colspan="9">
            <div class="preview-subhead-name">R&R</div>
          </td>
          <td>
            <div class="preview-subhead-name preview-amount-header">Amount</div>
          </td>
        </tr>
        <tr v-if="!!estimateTo.line_items.lines.RR.length && estimateTo.line_items.summary['RR-Hrs-SubTotal-Str'] ==''"
            v-for="line in estimateTo.line_items.lines.RR">
          <td colspan="9">
            <div class="preview-item-name">{{line.name}}
              <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                Comment: <span>{{line.manual_pdf_comment}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="preview-item-value">{{line.total_str}}</div>
          </td>
        </tr>
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.RR.length && estimateTo.line_items.summary['RR-Hrs-SubTotal-Str'] ==''">
          <td colspan="9">
            <div class="preview-item-name preview-align-right">Subtotal</div>
          </td>
          <td>
            <div class="preview-item-name  preview-amount">{{estimateTo.line_items.summary["RR-Total-SubTotal-Str"]}}</div>
          </td>
        </tr>
        <!--
       Delimiter
      -->
        <tr class="nobrd">
          <td colspan="10">
            <div class="preview-delimiter"></div>
          </td>
        </tr>
        <!--
         Repair with hours
        -->
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Repair.length && estimateTo.line_items.summary['Repair-Hrs-SubTotal-Str'] !=''">
          <td colspan="6">
            <div class="preview-subhead-name">Repair</div>
          </td>
          <td>
            <div class="preview-subhead-name">
              <span v-if="isEstimateMethodologyDollar"></span>
              <span v-else>Hrs</span>
            </div>
          </td>
          <td>
            <div class="preview-subhead-name">
              <span v-if="isEstimateMethodologyDollar"></span>
              <span v-else>Rate</span>
            </div>
          </td>
          <td>
            <div class="preview-subhead-name"></div>
          </td>
          <td>
            <div class="preview-subhead-name preview-amount-header">Amount</div>
          </td>
        </tr>
        <tr v-if="!!estimateTo.line_items.lines.Repair.length && estimateTo.line_items.summary['Repair-Hrs-SubTotal-Str'] !=''"
            v-for="line in estimateTo.line_items.lines.Repair">
          <td colspan="6">
            <div class="preview-item-name">{{line.name}}
              <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                Comment: <span>{{line.manual_pdf_comment}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="preview-item-value">
              <span v-if="isEstimateMethodologyDollar"></span>
              <span v-else>{{line.hrs_str}}</span>
            </div>
          </td>
          <td>
            <div class="preview-item-value">
              <span v-if="isEstimateMethodologyDollar"></span>
              <span v-else>{{line.rate_str}}</span>
            </div>
          </td>
          <td>
            <div class="preview-item-value"></div>
          </td>
          <td>
            <div class="preview-item-value preview-amount">{{line.total_str}}</div>
          </td>
        </tr>
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Repair.length && estimateTo.line_items.summary['Repair-Hrs-SubTotal-Str'] !=''">
          <td colspan="6">
            <div class="preview-item-name preview-align-right">Subtotal Hrs</div>
          </td>
          <td>
            <div class="preview-item-name">{{estimateTo.line_items.summary["Repair-Hrs-SubTotal-Str"]}}</div>
          </td>
          <td>
            <div class="preview-item-value"></div>
          </td>
          <td>
            <div class="preview-item-name preview-align-right">Subtotal</div>
          </td>
          <td>
            <div class="preview-item-name  preview-amount">{{estimateTo.line_items.summary["Repair-Total-SubTotal-Str"]}}</div>
          </td>
        </tr>
        <!--
        Repair without hours
         -->
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Repair.length && estimateTo.line_items.summary['Repair-Hrs-SubTotal-Str'] ==''">
          <td colspan="9">
            <div class="preview-subhead-name">Repair</div>
          </td>
          <td>
            <div class="preview-subhead-name preview-amount-header">Amount</div>
          </td>
        </tr>
        <tr v-if="!!estimateTo.line_items.lines.Repair.length && estimateTo.line_items.summary['Repair-Hrs-SubTotal-Str'] ==''"
            v-for="line in estimateTo.line_items.lines.Repair">
          <td colspan="9">
            <div class="preview-item-name">{{line.name}}
              <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                Comment: <span>{{line.manual_pdf_comment}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="preview-item-value preview-amount">{{line.total_str}}</div>
          </td>
        </tr>
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Repair.length && estimateTo.line_items.summary['Repair-Hrs-SubTotal-Str'] ==''">
          <td colspan="9">
            <div class="preview-item-name preview-align-right">Subtotal</div>
          </td>
          <td>
            <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Repair-Total-SubTotal-Str"]}}</div>
          </td>
        </tr>
        <!--
       Delimiter
      -->
        <tr v-if="!!estimateTo.line_items.lines.Repair.length" class="nobrd">
          <td colspan="10">
            <div class="preview-delimiter"></div>
          </td>
        </tr>
        <!--
                                       Paint with hours
                                       -->
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Paint.length && estimateTo.line_items.summary['Paint-Hrs-SubTotal-Str'] !=''">
          <td colspan="6">
            <div class="preview-subhead-name">Paint</div>
          </td>
          <td>
            <div class="preview-subhead-name">
              <span v-if="isEstimateMethodologyDollar"></span>
              <span v-else>Hrs</span>
            </div>
          </td>
          <td>
            <div class="preview-subhead-name">
              <span v-if="isEstimateMethodologyDollar"></span>
              <span v-else>Rate</span>
            </div>
          </td>
          <td>
            <div class="preview-subhead-name"></div>
          </td>
          <td>
            <div class="preview-subhead-name preview-amount-header">Amount</div>
          </td>
        </tr>
        <tr v-if="!!estimateTo.line_items.lines.Paint.length && estimateTo.line_items.summary['Paint-Hrs-SubTotal-Str'] !=''"
            v-for="line in estimateTo.line_items.lines.Paint">
          <td colspan="6">
            <div class="preview-item-name">{{line.name}}
              <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                Comment: <span>{{line.manual_pdf_comment}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="preview-item-value">
              <span v-if="isEstimateMethodologyDollar"></span>
              <span v-else>{{line.hrs_str}}</span>
            </div>
          </td>
          <td>
            <div class="preview-item-value">
              <span v-if="isEstimateMethodologyDollar"></span>
              <span v-else>{{line.rate_str}}</span>
            </div>
          </td>
          <td>
            <div class="preview-item-value"></div>
          </td>
          <td>
            <div class="preview-item-value preview-amount">{{line.total_str}}</div>
          </td>
        </tr>
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Paint.length && estimateTo.line_items.summary['Paint-Hrs-SubTotal-Str'] !=''">
          <td colspan="6">
            <div class="preview-item-name preview-align-right">Subtotal Hrs</div>
          </td>
          <td>
            <div class="preview-item-name">{{estimateTo.line_items.summary["Paint-Hrs-SubTotal-Str"]}}</div>
          </td>
          <td>
            <div class="preview-item-value"></div>
          </td>
          <td>
            <div class="preview-item-name preview-align-right">Subtotal</div>
          </td>
          <td>
            <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Paint-Total-SubTotal-Str"]}}</div>
          </td>
        </tr>
        <!--
                                        Paint without hours
                                         -->
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Paint.length && estimateTo.line_items.summary['Paint-Hrs-SubTotal-Str'] ==''">
          <td colspan="9">
            <div class="preview-subhead-name">Paint</div>
          </td>
          <td>
            <div class="preview-subhead-name preview-amount-header">Amount</div>
          </td>
        </tr>
        <tr v-if="!!estimateTo.line_items.lines.Paint.length && estimateTo.line_items.summary['Paint-Hrs-SubTotal-Str'] ==''"
            v-for="line in estimateTo.line_items.lines.Paint">
          <td colspan="9">
            <div class="preview-item-name">{{line.name}}
              <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                Comment: <span>{{line.manual_pdf_comment}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="preview-item-value preview-amount">{{line.total_str}}</div>
          </td>
        </tr>
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Paint.length && estimateTo.line_items.summary['Paint-Hrs-SubTotal-Str'] ==''">
          <td colspan="9">
            <div class="preview-item-name preview-align-right">Subtotal</div>
          </td>
          <td>
            <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Paint-Total-SubTotal-Str"]}}</div>
          </td>
        </tr>
        <!--
        Labour
        -->
        <tr class="nobrd"
            v-if="(!!estimateTo.line_items.lines.Paint.length || !!estimateTo.line_items.lines.Repair.length || !!estimateTo.line_items.lines.RR.length) && estimateTo.line_items.summary['Labour-Hrs-Str'] != ''">
          <td colspan="6">
            <div class="preview-item-name preview-align-right">Total Labour Hours</div>
          </td>
          <td>
            <div class="preview-item-name">{{estimateTo.line_items.summary["Labour-Hrs-Str"]}}</div>
          </td>
          <td>
            <div class="preview-item-value"></div>
          </td>
          <td>
            <div class="preview-item-name preview-align-right">Subtotal Amount</div>
          </td>
          <td>
            <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Labour-Cost-Str"]}}</div>
          </td>
        </tr>
        <tr class="nobrd"
            v-if="(!!estimateTo.line_items.lines.Paint.length || !!estimateTo.line_items.lines.Repair.length || !!estimateTo.line_items.lines.RR.length) && estimateTo.line_items.summary['Labour-Hrs-Str'] == ''">
          <td colspan="9">
            <div class="preview-item-name preview-align-right">Subtotal Amount</div>
          </td>
          <td>
            <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Labour-Cost-Str"]}}</div>
          </td>
        </tr>
        <!--
       Delimiter
      -->
        <tr v-if="!!estimateTo.line_items.lines.Paint.length" class="nobrd">
          <td colspan="10">
            <div class="preview-delimiter"></div>
          </td>
        </tr>
        <!--
         Parts
        -->
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Part.length">
          <td colspan="4">
            <div class="preview-subhead-name">Parts</div>
          </td>
          <td>
            <div class="preview-subhead-name">Part No</div>
          </td>
          <td>
            <div class="preview-subhead-name">Qty</div>
          </td>
          <td>
            <div class="preview-subhead-name">M/up</div>
          </td>
          <td>
            <div class="preview-subhead-name preview-subhead-unit-price">Unit Price</div>
          </td>
          <td>
            <div class="preview-subhead-name"></div>
          </td>
          <td>
            <div class="preview-subhead-name preview-amount-header">Amount</div>
          </td>
        </tr>
        <tr v-if="!!estimateTo.line_items.lines.Part.length" v-for="line in estimateTo.line_items.lines.Part">
          <td colspan="4">
            <div class="preview-item-name">{{line.name}}
              <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                Comment: <span>{{line.manual_pdf_comment}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="preview-item-value">{{line.part_num}}</div>
          </td>
          <td>
            <div class="preview-item-value">{{line.qty}}</div>
          </td>
          <td>
            <div class="preview-item-value">{{line.mup}}%</div>
          </td>
          <td>
            <div class="preview-item-value">{{line.rate_str}}</div>
          </td>
          <td>
            <div class="preview-item-value"></div>
          </td>
          <td>
            <div class="preview-item-value preview-amount">{{line.total_str}}</div>
          </td>
        </tr>
        <tr v-if="!!estimateTo.line_items.lines.Part.length" class="nobrd">
          <td colspan="9">
            <div class="preview-item-name preview-align-right">Subtotal</div>
          </td>
          <td>
            <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Part-Total-SubTotal-Str"]}}</div>
          </td>
        </tr>
        <!--
        Delimiter
        -->
        <tr v-if="!!estimateTo.line_items.lines.Part.length" class="nobrd">
          <td colspan="10">
            <div class="preview-delimiter"></div>
          </td>
        </tr>
        <!--
        PDR
        -->
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.PDR.length">
          <td colspan="5">
            <div class="preview-subhead-name">PDR</div>
          </td>
          <td>
            <div class="preview-subhead-name">Material</div>
          </td>
          <td>
            <div class="preview-subhead-name">Action</div>
          </td>
          <td>
            <div class="preview-subhead-name">Qty</div>
          </td>
          <td>
            <div class="preview-subhead-name">Price</div>
          </td>
          <td>
            <div class="preview-subhead-name preview-amount-header">Amount</div>
          </td>
        </tr>
        <tr v-if="!!estimateTo.line_items.lines.PDR.length" v-for="line in estimateTo.line_items.lines.PDR">
          <td colspan="5">
            <div class="preview-item-name">{{line.name}}
              <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                Comment: <span>{{line.manual_pdf_comment}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="preview-item-value">{{line.type === 'panel' ? line.material : ''}}</div>
          </td>
          <td>
            <div class="preview-item-value">{{line.type === 'panel' ? line.action : ''}}</div>
          </td>
          <td>
            <div class="preview-item-value">{{line.qty}}</div>
          </td>
          <td>
            <div class="preview-item-value">{{line.price}}</div>
          </td>
          <td>
            <div class="preview-item-value preview-amount">{{line.total_str}}</div>
          </td>
        </tr>
        <tr v-if="!!estimateTo.line_items.lines.PDR.length" class="nobrd">
          <td colspan="9">
            <div class="preview-item-name preview-align-right">Subtotal</div>
          </td>
          <td>
            <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["PDR-Total-SubTotal-Str"]}}</div>
          </td>
        </tr>
        <!--
        Delimiter
        -->
        <tr v-if="!!estimateTo.line_items.lines.PDR.length" class="nobrd">
          <td colspan="10">
            <div class="preview-delimiter"></div>
          </td>
        </tr>
        <!--
        Materials
                -->
        <tr v-if="!!estimateTo.line_items.lines.Materials.length" class="nobrd">
          <td colspan="7">
            <div class="preview-subhead-name">Materials</div>
          </td>
          <td>
            <div class="preview-subhead-name">Hrs</div>
          </td>
          <td>
            <div class="preview-subhead-name"></div>
          </td>
          <td>
            <div class="preview-subhead-name preview-amount-header">Amount</div>
          </td>
        </tr>
        <tr v-if="!!estimateTo.line_items.lines.Materials.length" v-for="line in estimateTo.line_items.lines.Materials">
          <td colspan="7">
            <div class="preview-item-name">{{line.name}}
              <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                Comment: <span>{{line.manual_pdf_comment}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="preview-item-value">{{line.hrs_str}}</div>
          </td>
          <td>
            <div class="preview-item-value"></div>
          </td>
          <td>
            <div class="preview-item-value preview-amount">{{line.total_str}}</div>
          </td>
        </tr>
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Materials.length">
          <td colspan="9">
            <div class="preview-item-name preview-align-right">Subtotal</div>
          </td>
          <td>
            <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Materials-Total-Str"]}}</div>
          </td>
        </tr>
        <!--
        Delimiter
        -->
        <tr v-if="!!estimateTo.line_items.lines.Materials.length" class="nobrd">
          <td colspan="10">
            <div class="preview-delimiter"></div>
          </td>
        </tr>
        <!--
        Sublet with Hrs
       -->
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Sublet.length && estimateTo.line_items.summary['Sublet-Hrs-SubTotal-Str'] !=''">
          <td colspan="5">
            <div class="preview-subhead-name">Sublet</div>
          </td>
          <td>
            <div class="preview-subhead-name">Repairer</div>
          </td>
          <td>
            <div class="preview-subhead-name">Hrs</div>
          </td>
          <td>
            <div class="preview-subhead-name">Rate</div>
          </td>
          <td>
            <div class="preview-subhead-name"></div>
          </td>
          <td>
            <div class="preview-subhead-name preview-amount-header">Amount</div>
          </td>
        </tr>
        <tr v-if="!!estimateTo.line_items.lines.Sublet.length && estimateTo.line_items.summary['Sublet-Hrs-SubTotal-Str'] !=''"
            v-for="line in estimateTo.line_items.lines.Sublet">
          <td colspan="5">
            <div class="preview-item-name">{{line.name}}
              <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                Comment: <span>{{line.manual_pdf_comment}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="preview-item-value">{{line.repairer}}</div>
          </td>
          <td>
            <div class="preview-item-value">{{line.hrs_str}}</div>
          </td>
          <td>
            <div class="preview-item-value">{{line.rate_str}}</div>
          </td>
          <td>
            <div class="preview-item-value"></div>
          </td>
          <td>
            <div class="preview-item-value preview-amount">{{line.total_str}}</div>
          </td>
        </tr>
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Sublet.length && estimateTo.line_items.summary['Sublet-Hrs-SubTotal-Str'] != ''">
          <td colspan="7">
            <div class="preview-item-name preview-align-right">Subtotal Hrs</div>
          </td>
          <td>
            <div class="preview-item-name preview-align-right">{{estimateTo.line_items.summary["Sublet-Hrs-SubTotal-Str"]}}</div>
          </td>
          <td>
            <div class="preview-item-name preview-align-right">Subtotal</div>
          </td>
          <td>
            <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Sublet-Total-SubTotal-Str"]}}</div>
          </td>
        </tr>
        <!--
       Sublet without Hrs
      -->
        <tr v-if="!!estimateTo.line_items.lines.Sublet.length && estimateTo.line_items.summary['Sublet-Hrs-SubTotal-Str'] ==''" class="nobrd">
          <td colspan="5">
            <div class="preview-subhead-name">Sublet</div>
          </td>
          <td>
            <div class="preview-subhead-name">Repairer</div>
          </td>
          <td>
            <div class="preview-subhead-name"></div>
          </td>
          <td>
            <div class="preview-subhead-name"></div>
          </td>
          <td>
            <div class="preview-subhead-name"></div>
          </td>
          <td>
            <div class="preview-subhead-name preview-amount-header">Amount</div>
          </td>
        </tr>
        <tr v-if="!!estimateTo.line_items.lines.Sublet.length && estimateTo.line_items.summary['Sublet-Hrs-SubTotal-Str'] ==''"
            v-for="line in estimateTo.line_items.lines.Sublet">
          <td colspan="5">
            <div class="preview-item-name">{{line.name}}
              <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                Comment: <span>{{line.manual_pdf_comment}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="preview-item-value">{{line.repairer}}</div>
          </td>
          <td>
            <div class="preview-item-value"></div>
          </td>
          <td>
            <div class="preview-item-value"></div>
          </td>
          <td>
            <div class="preview-item-value"></div>
          </td>
          <td>
            <div class="preview-item-value preview-amount">{{line.total_str}}</div>
          </td>
        </tr>
        <tr v-if="!!estimateTo.line_items.lines.Sublet.length && estimateTo.line_items.summary['Sublet-Hrs-SubTotal-Str'] == ''" class="nobrd">
          <td colspan="9">
            <div class="preview-item-name preview-align-right">Subtotal</div>
          </td>
          <td>
            <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Sublet-Total-SubTotal-Str"]}}</div>
          </td>
        </tr>
        <!--
       Delimiter
      -->
        <tr v-if="!!estimateTo.line_items.lines.Sublet.length" class="nobrd">
          <td colspan="10">
            <div class="preview-delimiter"></div>
          </td>
        </tr>
        <!--
         Other
        -->
        <tr v-if="!!estimateTo.line_items.lines.Other.length" class="nobrd">
          <td colspan="9">
            <div class="preview-subhead-name">
              <template v-if="isVendorBrandingType2">Item</template>
              <template v-else-if="isVendorBrandingType3">Description</template>
              <template v-else>Other</template>
            </div>
          </td>
          <td>
            <div class="preview-subhead-name preview-amount-header">Amount</div>
          </td>
        </tr>
        <tr v-if="!!estimateTo.line_items.lines.Other.length" v-for="line in estimateTo.line_items.lines.Other">
          <td colspan="9">
            <div class="preview-item-name">{{line.name}}
              <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                Comment: <span>{{line.manual_pdf_comment}}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="preview-item-value preview-amount">{{line.total_str}}</div>
          </td>
        </tr>
        <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Other.length">
          <td colspan="9">
            <div class="preview-item-name preview-align-right">Subtotal</div>
          </td>
          <td>
            <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Other-Total-SubTotal-Str"]}}</div>
          </td>
        </tr>
        <!--
       Delimiter
      -->
        <tr v-if="!!estimateTo.line_items.lines.Other.length" class="nobrd">
          <td colspan="10">
            <div class="preview-delimiter"></div>
          </td>
        </tr>
        <!--
        Total
        -->
        <tr v-if="countSubtotal > 1" class="nobrd">
          <td colspan="9">
            <div class="preview-item-name preview-align-right">Total</div>
          </td>
          <td>
            <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Total_str"]}}</div>
          </td>
        </tr>
        <tr class="nobrd">
          <td colspan="9">
            <div class="preview-item-name preview-align-right">GST</div>
          </td>
          <td>
            <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary.GST_str}}</div>
          </td>
        </tr>
        <tr class="nobrd">
          <td colspan="8">
            <div class="preview-item-name preview-align-right"></div>
          </td>
          <td colspan="">
            <div class="preview-item-name preview-align-right"
                 style="border-top: 1px solid rgba(27, 30, 56, 0.25) !important;margin-left: 45px; padding-left: 0px;
">Total Inc GST
            </div>
          </td>
          <td>
            <div class="preview-item-name preview-amount"
                 style="border-top:1px solid rgba(27,30,56,0.25) !important; position: relative;"
            >{{estimateTo.line_items.summary.GrandTotal_str}}
              <div class="preview-total-plus"></div>
            </div>

          </td>
        </tr>
        <tr class="nobrd">
          <td colspan="10">
            <div class="preview-terms-delimeter"></div>
          </td>
        </tr>
        <tr class="nobrd">
          <td colspan="10">
            <div class="preview-terms-header">Terms</div>
          </td>
        </tr>
        <tr class="nobrd">
          <td colspan="10">
            <div class="preview-terms" v-html="estimateTo.terms"></div>
          </td>
        </tr>

      </table>

      <table v-else class="table b-table table-preview rs-scroll rs-scroll--y">
            <tr class="nobrd">
                <td colspan="7">
                    <div class="preview-logo"><img :src="vendorInfo.logo" style="height: 150px; width: 300px;" alt=""></div>
                </td>
                <td colspan="3">
                    <div class="preview-header">
                        <div class="preview-header-title">ESTIMATE</div>
                        <div class="preview-header-business-name">{{estimateTo.vendor.business_name}}</div>
                        <div class="preview-header-item"><span class="preview-header-item-name">ABN:</span> <span class="preview-header-item-value">{{estimateTo.vendor.abn}}</span>
                        </div>
                        <div class="preview-header-item"><span class="preview-header-item-name">License:</span> <span
                                class="preview-header-item-value">{{estimateTo.vendor.license_number}}</span>
                        </div>
                        <div class="preview-header-item" v-if="estimateTo.vendor.postal_address"><span class="preview-header-item-value">{{estimateTo.vendor.postal_address}}</span>
                        </div>
                        <div class="preview-header-item" v-if="!estimateTo.vendor.postal_address"><span class="preview-header-item-value">{{estimateTo.vendor.postal_street}} {{estimateTo.vendor.postal_suburb}}</span>
                        </div>
                        <div class="preview-header-item" v-if="!estimateTo.vendor.postal_address"><span class="preview-header-item-value">{{estimateTo.vendor.postal_state}} {{estimateTo.vendor.postal_country}}</span>
                        </div>
                        <div class="preview-header-item"><span class="preview-header-item-name">Email:</span> <span class="preview-header-item-value">{{estimateTo.vendor.email}}</span>
                        </div>
                        <div class="preview-header-item"><span class="preview-header-item-name">Phone:</span> <span class="preview-header-item-value">{{estimateTo.vendor.phone_area_code}} {{estimateTo.vendor.phone}}</span>
                        </div>
                        <div class="preview-header-delimiter"></div>
                        <div class="preview-header-item"><span class="preview-header-item-name">Estimate Number: </span>
                          <span v-if="estimateTo.estimate.supplement_number > 0" class="preview-header-item-value"> {{estimateTo.estimate.estimate_number}} - {{estimateTo.estimate.supplement_number}}</span>
                          <span v-else class="preview-header-item-value">{{estimateTo.estimate.estimate_number}}</span>
                        </div>
                        <div v-if="!isVendorBrandingType1" class="preview-header-item"><span class="preview-header-item-name">Estimator:</span> <span class="preview-header-item-value">{{estimateTo.estimate.estimator}}</span>
                        </div>
                        <div class="preview-header-item"><span class="preview-header-item-name">Date:</span> <span class="preview-header-item-value">{{estimateTo.estimate.created_on}}</span>
                        </div>
                        <div class="preview-header-item"><span class="preview-header-item-name">Expiry:</span> <span class="preview-header-item-value">{{estimateTo.estimate.expires_on}}</span>
                        </div>
                    </div>
                </td>
            </tr>
            <tr v-if="!isVendorBrandingType2" class="nobrd">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr  v-if="!isVendorBrandingType2" class="nobrd">
                <td colspan="10">
                    <div class="preview-head-name">Vehicle & Owner</div>
                </td>
            </tr>
            <tr v-if="!isVendorBrandingType2" class="nobrd">
                <td colspan="4">
                    <div class="preview-subhead-name">Owner Detail</div>
                </td>
                <td colspan="6">
                    <div class="preview-subhead-name">Vehicle Detail</div>
                </td>
            </tr>
            <tr v-if="!isVendorBrandingType2" class="nobrd">
                <td colspan="4">
                    <table>
                        <tr class="nobrd">
                            <td class="w-25"></td>
                            <td class="w-25"></td>
                            <td class="w-25"></td>
                            <td class="w-25"></td>
                        </tr>
                        <tr class="nobrd">
                            <td>
                                <div class="preview-item-name">Owner Name:</div>
                            </td>
                            <td colspan="3">
                                <div class="preview-item-value">{{estimateTo.customer.customer_name}}</div>
                            </td>
                        </tr>
                        <tr class="nobrd">
                            <td>
                                <div class="preview-item-name">Home Phone:</div>
                            </td>
                            <td colspan="3">
                                <div class="preview-item-value">{{estimateTo.customer.phone}}</div>
                            </td>
                        </tr>
                        <tr class="nobrd">
                            <td>
                                <div class="preview-item-name">Mobile Phone:</div>
                            </td>
                            <td colspan="3">
                                <div class="preview-item-value">{{estimateTo.customer.mobile}}</div>
                            </td>
                        </tr>
                        <tr class="nobrd">
                            <td>
                                <div class="preview-item-name">Email:</div>
                            </td>
                            <td colspan="3">
                                <div class="preview-item-value">{{estimateTo.customer.email}}</div>
                            </td>
                        </tr>
                        <tr class="nobrd" v-if="estimateTo.customer.customer_type == 'B'">
                            <td>
                                <div class="preview-item-name">ABN:</div>
                            </td>
                            <td colspan="3">
                                <div class="preview-item-value">{{estimateTo.customer.abn}}</div>
                            </td>
                        </tr>
                        <tr class="nobrd" v-if="!isVendorBrandingType1">
                            <td>
                                <div class="preview-item-name">Insurer Name:</div>
                            </td>
                            <td colspan="3">
                                <div class="preview-item-value">{{estimateTo.insurance.insurer_name}}</div>
                            </td>
                        </tr>
                        <tr class="nobrd" v-if="!isVendorBrandingType1">
                          <td>
                            <div class="preview-item-name">Insurer ABN:</div>
                          </td>
                          <td colspan="3">
                            <div class="preview-item-value">{{estimateTo.insurance.abn}}</div>
                          </td>
                        </tr>
                        <tr class="nobrd">
                            <td>
                                <div class="preview-item-name">Claim Number:</div>
                            </td>
                            <td colspan="3">
                                <div class="preview-item-value">{{estimateTo.insurance.claim_number}}</div>
                            </td>
                        </tr>
                    </table>
                </td>
                <td colspan="6">
                    <table class="nobrd">
                        <tr>
                            <td class="w-50">
                                <table class="nobrd">
                                    <tr>
                                        <td class="w-33"></td>
                                        <td class="w-33"></td>
                                        <td class="w-33"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="preview-item-name">Make:</div>
                                        </td>
                                        <td colspan="2">
                                            <div class="preview-item-value">{{estimateTo.customer_vehicle.make}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="preview-item-name">Model:</div>
                                        </td>
                                        <td colspan="2">
                                            <div class="preview-item-value">{{estimateTo.customer_vehicle.model}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="preview-item-name">Series:</div>
                                        </td>
                                        <td colspan="2">
                                            <div v-if="estimateTo && estimateTo.properties && estimateTo.properties.isHideSeries && estimateTo.properties.isHideSeries === true"
                                                 class="preview-item-value"> </div>
                                            <div v-else class="preview-item-value">{{estimateTo.customer_vehicle.series}}</div>
                                        </td>
                                    </tr>
                                    <tr v-if="!isVendorBrandingType1 && !isEstimateMethodologyCustom && estimateTo.customer_vehicle.series_shedule_number != ''">
                                        <td>
                                            <div class="preview-item-name">Schedule Number:</div>
                                        </td>
                                        <td colspan="2">
                                            <div class="preview-item-value">{{estimateTo.customer_vehicle.series_shedule_number}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="preview-item-name">Transmission:</div>
                                        </td>
                                        <td colspan="2">
                                            <div class="preview-item-value">{{estimateTo.customer_vehicle.transmission}}</div>
                                        </td>
                                    </tr>
                                    <tr v-if="!isVendorBrandingType1">
                                        <td>
                                            <div class="preview-item-name">Paint Code:</div>
                                        </td>
                                        <td colspan="2">
                                            <div class="preview-item-value">{{estimateTo.customer_vehicle.colour}}</div>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="w-50">
                                <table class="nobrd">
                                    <tr>
                                        <td class="w-33"></td>
                                        <td class="w-33"></td>
                                        <td class="w-33"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="preview-item-name">Rego:</div>
                                        </td>
                                        <td colspan="2">
                                            <div class="preview-item-value">{{estimateTo.customer_vehicle.rego_number}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="preview-item-name">VIN Number:</div>
                                        </td>
                                        <td colspan="2">
                                            <div class="preview-item-value">{{estimateTo.customer_vehicle.vin}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="preview-item-name">Cylinders:</div>
                                        </td>
                                        <td colspan="2">
                                            <div class="preview-item-value">{{estimateTo.customer_vehicle.cylinders}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="preview-item-name">Build Date:</div>
                                        </td>
                                        <td colspan="2">
                                            <div class="preview-item-value">{{estimateTo.customer_vehicle.dom}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="preview-item-name">Odometer:</div>
                                        </td>
                                        <td colspan="2">
                                            <div class="preview-item-value">{{estimateTo.customer_vehicle.odometer}}</div>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr v-if="!isVendorBrandingType2" class="nobrd">
                <td colspan="10">
                    <div class="preview-delimiter"></div>
                </td>
            </tr>
            <tr class="nobrd">
                <td colspan="10">
                    <div class="preview-head-name">Repairs</div>
                </td>
            </tr>
            <!--
             RR with hours
            -->
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.RR.length && estimateTo.line_items.summary['RR-Hrs-SubTotal-Str'] !=''">
                <td colspan="6">
                    <div class="preview-subhead-name">R&R</div>
                </td>
                <td>
                    <div class="preview-subhead-name">
                      <span v-if="isEstimateMethodologyDollar"></span>
                      <span v-else>Hrs</span>
                    </div>
                </td>
                <td>
                    <div class="preview-subhead-name">
                      <span v-if="isEstimateMethodologyDollar"></span>
                      <span v-else>Rate</span>
                    </div>
                </td>
                <td>
                    <div class="preview-subhead-name preview-amount-header">Amount</div>
                </td>
            </tr>
            <tr v-if="!!estimateTo.line_items.lines.RR.length && estimateTo.line_items.summary['RR-Hrs-SubTotal-Str'] !=''"
                v-for="line in estimateTo.line_items.lines.RR">
                <td colspan="6">
                    <div class="preview-item-name">{{line.name}}
                        <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                            Comment: <span>{{line.manual_pdf_comment}}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value">
                      <span v-if="isEstimateMethodologyDollar"></span>
                      <span v-else>{{line.hrs_str}}</span>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value">
                      <span v-if="isEstimateMethodologyDollar"></span>
                      <span v-else>{{line.rate_str}}</span>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value preview-amount">{{line.total_str}}</div>
                </td>
            </tr>
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.RR.length && estimateTo.line_items.summary['RR-Hrs-SubTotal-Str'] !=''">
                <td colspan="5">
                    <div class="preview-item-name preview-align-right">Subtotal Hrs</div>
                </td>
                <td>
                    <div class="preview-item-name">{{estimateTo.line_items.summary["RR-Hrs-SubTotal-Str"]}}</div>
                </td>
                <td>
                    <div class="preview-item-value"></div>
                </td>
                <td>
                    <div class="preview-item-name preview-align-right">Subtotal</div>
                </td>
                <td>
                    <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["RR-Total-SubTotal-Str"]}}</div>
                </td>
            </tr>
            <!--
            RR without hours
           -->
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.RR.length && estimateTo.line_items.summary['RR-Hrs-SubTotal-Str'] ==''">
                <td colspan="9">
                    <div class="preview-subhead-name">R&R</div>
                </td>
                <td>
                    <div class="preview-subhead-name preview-amount-header">Amount</div>
                </td>
            </tr>
            <tr v-if="!!estimateTo.line_items.lines.RR.length && estimateTo.line_items.summary['RR-Hrs-SubTotal-Str'] ==''"
                v-for="line in estimateTo.line_items.lines.RR">
                <td colspan="9">
                    <div class="preview-item-name">{{line.name}}
                        <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                            Comment: <span>{{line.manual_pdf_comment}}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value">{{line.total_str}}</div>
                </td>
            </tr>
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.RR.length && estimateTo.line_items.summary['RR-Hrs-SubTotal-Str'] ==''">
                <td colspan="9">
                    <div class="preview-item-name preview-align-right">Subtotal</div>
                </td>
                <td>
                    <div class="preview-item-name  preview-amount">{{estimateTo.line_items.summary["RR-Total-SubTotal-Str"]}}</div>
                </td>
            </tr>
            <!--
           Delimiter
          -->
            <tr class="nobrd">
                <td colspan="10">
                    <div class="preview-delimiter"></div>
                </td>
            </tr>
            <!--
             Repair with hours
            -->
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Repair.length && estimateTo.line_items.summary['Repair-Hrs-SubTotal-Str'] !=''">
                <td colspan="6">
                    <div class="preview-subhead-name">Repair</div>
                </td>
                <td>
                    <div class="preview-subhead-name">
                      <span v-if="isEstimateMethodologyDollar"></span>
                      <span v-else>Hrs</span>
                    </div>
                </td>
                <td>
                    <div class="preview-subhead-name">
                      <span v-if="isEstimateMethodologyDollar"></span>
                      <span v-else>Rate</span>
                    </div>
                </td>
                <td>
                    <div class="preview-subhead-name preview-amount-header">Amount</div>
                </td>
            </tr>
            <tr v-if="!!estimateTo.line_items.lines.Repair.length && estimateTo.line_items.summary['Repair-Hrs-SubTotal-Str'] !=''"
                v-for="line in estimateTo.line_items.lines.Repair">
                <td colspan="6">
                    <div class="preview-item-name">{{line.name}}
                        <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                            Comment: <span>{{line.manual_pdf_comment}}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value">
                      <span v-if="isEstimateMethodologyDollar"></span>
                      <span v-else>{{line.hrs_str}}</span>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value">
                      <span v-if="isEstimateMethodologyDollar"></span>
                      <span v-else>{{line.rate_str}}</span>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value preview-amount">{{line.total_str}}</div>
                </td>
            </tr>
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Repair.length && estimateTo.line_items.summary['Repair-Hrs-SubTotal-Str'] !=''">
                <td colspan="5">
                    <div class="preview-item-name preview-align-right">Subtotal Hrs</div>
                </td>
                <td>
                    <div class="preview-item-name">{{estimateTo.line_items.summary["Repair-Hrs-SubTotal-Str"]}}</div>
                </td>
                <td>
                    <div class="preview-item-value"></div>
                </td>
                <td>
                    <div class="preview-item-name preview-align-right">Subtotal</div>
                </td>
                <td>
                    <div class="preview-item-name  preview-amount">{{estimateTo.line_items.summary["Repair-Total-SubTotal-Str"]}}</div>
                </td>
            </tr>
            <!--
            Repair without hours
             -->
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Repair.length && estimateTo.line_items.summary['Repair-Hrs-SubTotal-Str'] ==''">
                <td colspan="9">
                    <div class="preview-subhead-name">Repair</div>
                </td>
                <td>
                    <div class="preview-subhead-name preview-amount-header">Amount</div>
                </td>
            </tr>
            <tr v-if="!!estimateTo.line_items.lines.Repair.length && estimateTo.line_items.summary['Repair-Hrs-SubTotal-Str'] ==''"
                v-for="line in estimateTo.line_items.lines.Repair">
                <td colspan="9">
                    <div class="preview-item-name">{{line.name}}
                        <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                            Comment: <span>{{line.manual_pdf_comment}}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value preview-amount">{{line.total_str}}</div>
                </td>
            </tr>
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Repair.length && estimateTo.line_items.summary['Repair-Hrs-SubTotal-Str'] ==''">
                <td colspan="9">
                    <div class="preview-item-name preview-align-right">Subtotal</div>
                </td>
                <td>
                    <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Repair-Total-SubTotal-Str"]}}</div>
                </td>
            </tr>
            <!--
           Delimiter
          -->
            <tr v-if="!!estimateTo.line_items.lines.Repair.length" class="nobrd">
                <td colspan="10">
                    <div class="preview-delimiter"></div>
                </td>
            </tr>
            <!--
                                           Paint with hours
                                           -->
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Paint.length && estimateTo.line_items.summary['Paint-Hrs-SubTotal-Str'] !=''">
                <td colspan="6">
                    <div class="preview-subhead-name">Paint</div>
                </td>
                <td>
                    <div class="preview-subhead-name">
                      <span v-if="isEstimateMethodologyDollar"></span>
                      <span v-else>Hrs</span>
                    </div>
                </td>
                <td>
                    <div class="preview-subhead-name">
                      <span v-if="isEstimateMethodologyDollar"></span>
                      <span v-else>Rate</span>
                    </div>
                </td>
                <td>
                    <div class="preview-subhead-name preview-amount-header">Amount</div>
                </td>
            </tr>
            <tr v-if="!!estimateTo.line_items.lines.Paint.length && estimateTo.line_items.summary['Paint-Hrs-SubTotal-Str'] !=''"
                v-for="line in estimateTo.line_items.lines.Paint">
                <td colspan="6">
                    <div class="preview-item-name">{{line.name}}
                        <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                            Comment: <span>{{line.manual_pdf_comment}}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value">
                      <span v-if="isEstimateMethodologyDollar"></span>
                      <span v-else>{{line.hrs_str}}</span>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value">
                      <span v-if="isEstimateMethodologyDollar"></span>
                      <span v-else>{{line.rate_str}}</span>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value preview-amount">{{line.total_str}}</div>
                </td>
            </tr>
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Paint.length && estimateTo.line_items.summary['Paint-Hrs-SubTotal-Str'] !=''">
                <td colspan="5">
                    <div class="preview-item-name preview-align-right">Subtotal Hrs</div>
                </td>
                <td>
                    <div class="preview-item-name">{{estimateTo.line_items.summary["Paint-Hrs-SubTotal-Str"]}}</div>
                </td>
                <td>
                    <div class="preview-item-value"></div>
                </td>
                <td>
                    <div class="preview-item-name preview-align-right">Subtotal</div>
                </td>
                <td>
                    <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Paint-Total-SubTotal-Str"]}}</div>
                </td>
            </tr>
            <!--
                                            Paint without hours
                                             -->
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Paint.length && estimateTo.line_items.summary['Paint-Hrs-SubTotal-Str'] ==''">
                <td colspan="9">
                    <div class="preview-subhead-name">Paint</div>
                </td>
                <td>
                    <div class="preview-subhead-name preview-amount-header">Amount</div>
                </td>
            </tr>
            <tr v-if="!!estimateTo.line_items.lines.Paint.length && estimateTo.line_items.summary['Paint-Hrs-SubTotal-Str'] ==''"
                v-for="line in estimateTo.line_items.lines.Paint">
                <td colspan="9">
                    <div class="preview-item-name">{{line.name}}
                        <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                            Comment: <span>{{line.manual_pdf_comment}}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value preview-amount">{{line.total_str}}</div>
                </td>
            </tr>
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Paint.length && estimateTo.line_items.summary['Paint-Hrs-SubTotal-Str'] ==''">
                <td colspan="9">
                    <div class="preview-item-name preview-align-right">Subtotal</div>
                </td>
                <td>
                    <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Paint-Total-SubTotal-Str"]}}</div>
                </td>
            </tr>
            <!--
            Labour
            -->
            <tr class="nobrd"
                v-if="(!!estimateTo.line_items.lines.Paint.length || !!estimateTo.line_items.lines.Repair.length || !!estimateTo.line_items.lines.RR.length) && estimateTo.line_items.summary['Labour-Hrs-Str'] != ''">
                <td colspan="5">
                    <div class="preview-item-name preview-align-right">Total Labour Hours</div>
                </td>
                <td>
                    <div class="preview-item-name">{{estimateTo.line_items.summary["Labour-Hrs-Str"]}}</div>
                </td>
                <td>
                    <div class="preview-item-value"></div>
                </td>
                <td>
                    <div class="preview-item-name preview-align-right">Subtotal Amount</div>
                </td>
                <td>
                    <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Labour-Cost-Str"]}}</div>
                </td>
            </tr>
            <tr class="nobrd"
                v-if="(!!estimateTo.line_items.lines.Paint.length || !!estimateTo.line_items.lines.Repair.length || !!estimateTo.line_items.lines.RR.length) && estimateTo.line_items.summary['Labour-Hrs-Str'] == ''">
                <td colspan="9">
                    <div class="preview-item-name preview-align-right">Subtotal Amount</div>
                </td>
                <td>
                    <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Labour-Cost-Str"]}}</div>
                </td>
            </tr>
            <!--
           Delimiter
          -->
            <tr v-if="!!estimateTo.line_items.lines.Paint.length" class="nobrd">
                <td colspan="10">
                    <div class="preview-delimiter"></div>
                </td>
            </tr>
            <!--
             Parts
            -->
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Part.length">
                <td colspan="4">
                    <div class="preview-subhead-name">Parts</div>
                </td>
                <td>
                    <div class="preview-subhead-name">Part No</div>
                </td>
                <td>
                    <div class="preview-subhead-name">Qty</div>
                </td>
                <td>
                    <div class="preview-subhead-name">M/up</div>
                </td>
                <td>
                    <div class="preview-subhead-name preview-subhead-unit-price">Unit Price</div>
                </td>
                <td>
                    <div class="preview-subhead-name preview-amount-header">Amount</div>
                </td>
            </tr>
            <tr v-if="!!estimateTo.line_items.lines.Part.length" v-for="line in estimateTo.line_items.lines.Part">
                <td colspan="4">
                    <div class="preview-item-name">{{line.name}}
                        <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                            Comment: <span>{{line.manual_pdf_comment}}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value">{{line.part_num}}</div>
                </td>
                <td>
                    <div class="preview-item-value">{{line.qty}}</div>
                </td>
                <td>
                    <div class="preview-item-value">{{line.mup}}%</div>
                </td>
                <td>
                    <div class="preview-item-value">{{line.rate_str}}</div>
                </td>
                <td>
                    <div class="preview-item-value preview-amount">{{line.total_str}}</div>
                </td>
            </tr>
            <tr v-if="!!estimateTo.line_items.lines.Part.length" class="nobrd">
                <td colspan="8">
                    <div class="preview-item-name preview-align-right">Subtotal</div>
                </td>
                <td>
                    <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Part-Total-SubTotal-Str"]}}</div>
                </td>
            </tr>
            <!--
            Delimiter
            -->
            <tr v-if="!!estimateTo.line_items.lines.Part.length" class="nobrd">
                <td colspan="10">
                    <div class="preview-delimiter"></div>
                </td>
            </tr>
            <!--
            PDR
            -->
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.PDR.length">
                <td colspan="4">
                    <div class="preview-subhead-name">PDR</div>
                </td>
                <td>
                    <div class="preview-subhead-name">Material</div>
                </td>
                <td>
                    <div class="preview-subhead-name">Action</div>
                </td>
                <td>
                    <div class="preview-subhead-name">Qty</div>
                </td>
                <td>
                    <div class="preview-subhead-name">Price</div>
                </td>
                <td>
                    <div class="preview-subhead-name preview-amount-header">Amount</div>
                </td>
            </tr>
            <tr v-if="!!estimateTo.line_items.lines.PDR.length" v-for="line in estimateTo.line_items.lines.PDR">
                <td colspan="4">
                    <div class="preview-item-name">{{line.name}}
                        <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                            Comment: <span>{{line.manual_pdf_comment}}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value">{{line.type === 'panel' ? line.material : ''}}</div>
                </td>
                <td>
                    <div class="preview-item-value">{{line.type === 'panel' ? line.action : ''}}</div>
                </td>
                <td>
                    <div class="preview-item-value">{{line.qty}}</div>
                </td>
                <td>
                    <div class="preview-item-value">{{line.price}}</div>
                </td>
                <td>
                    <div class="preview-item-value preview-amount">{{line.total_str}}</div>
                </td>
            </tr>
            <tr v-if="!!estimateTo.line_items.lines.PDR.length" class="nobrd">
                <td colspan="8">
                    <div class="preview-item-name preview-align-right">Subtotal</div>
                </td>
                <td>
                    <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["PDR-Total-SubTotal-Str"]}}</div>
                </td>
            </tr>
            <!--
            Delimiter
            -->
            <tr v-if="!!estimateTo.line_items.lines.PDR.length" class="nobrd">
                <td colspan="10">
                    <div class="preview-delimiter"></div>
                </td>
            </tr>
            <!--
            Materials
                    -->
            <tr v-if="!!estimateTo.line_items.lines.Materials.length" class="nobrd">
                <td colspan="7">
                    <div class="preview-subhead-name">Materials</div>
                </td>
                <td>
                    <div class="preview-subhead-name">Hrs</div>
                </td>
                <td>
                    <div class="preview-subhead-name preview-amount-header">Amount</div>
                </td>
            </tr>
            <tr v-if="!!estimateTo.line_items.lines.Materials.length" v-for="line in estimateTo.line_items.lines.Materials">
                <td colspan="7">
                    <div class="preview-item-name">{{line.name}}
                        <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                            Comment: <span>{{line.manual_pdf_comment}}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value">{{line.hrs_str}}</div>
                </td>
                <td>
                    <div class="preview-item-value preview-amount">{{line.total_str}}</div>
                </td>
            </tr>
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Materials.length">
                <td colspan="8">
                    <div class="preview-item-name preview-align-right">Subtotal</div>
                </td>
                <td>
                    <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Materials-Total-Str"]}}</div>
                </td>
            </tr>
            <!--
            Delimiter
            -->
            <tr v-if="!!estimateTo.line_items.lines.Materials.length" class="nobrd">
                <td colspan="10">
                    <div class="preview-delimiter"></div>
                </td>
            </tr>
            <!--
            Sublet with Hrs
           -->
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Sublet.length && estimateTo.line_items.summary['Sublet-Hrs-SubTotal-Str'] !=''">
                <td colspan="5">
                    <div class="preview-subhead-name">Sublet</div>
                </td>
                <td>
                    <div class="preview-subhead-name">Repairer</div>
                </td>
                <td>
                    <div class="preview-subhead-name">Hrs</div>
                </td>
                <td>
                    <div class="preview-subhead-name">Rate</div>
                </td>
                <td>
                    <div class="preview-subhead-name"></div>
                </td>
                <td>
                    <div class="preview-subhead-name preview-amount-header">Amount</div>
                </td>
            </tr>
            <tr v-if="!!estimateTo.line_items.lines.Sublet.length && estimateTo.line_items.summary['Sublet-Hrs-SubTotal-Str'] !=''"
                v-for="line in estimateTo.line_items.lines.Sublet">
                <td colspan="5">
                    <div class="preview-item-name">{{line.name}}
                        <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                            Comment: <span>{{line.manual_pdf_comment}}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value">{{line.repairer}}</div>
                </td>
                <td>
                    <div class="preview-item-value">{{line.hrs_str}}</div>
                </td>
                <td>
                    <div class="preview-item-value">{{line.rate_str}}</div>
                </td>
                <td>
                    <div class="preview-item-value"></div>
                </td>
                <td>
                    <div class="preview-item-value preview-amount">{{line.total_str}}</div>
                </td>
            </tr>
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Sublet.length && estimateTo.line_items.summary['Sublet-Hrs-SubTotal-Str'] != ''">
                <td colspan="7">
                    <div class="preview-item-name preview-align-right">Subtotal Hrs</div>
                </td>
                <td>
                    <div class="preview-item-name preview-align-right">{{estimateTo.line_items.summary["Sublet-Hrs-SubTotal-Str"]}}</div>
                </td>
                <td>
                    <div class="preview-item-name preview-align-right">Subtotal</div>
                </td>
                <td>
                    <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Sublet-Total-SubTotal-Str"]}}</div>
                </td>
            </tr>
            <!--
           Sublet without Hrs
          -->
            <tr v-if="!!estimateTo.line_items.lines.Sublet.length && estimateTo.line_items.summary['Sublet-Hrs-SubTotal-Str'] ==''" class="nobrd">
                <td colspan="5">
                    <div class="preview-subhead-name">Sublet</div>
                </td>
                <td>
                    <div class="preview-subhead-name">Repairer</div>
                </td>
                <td>
                    <div class="preview-subhead-name"></div>
                </td>
                <td>
                    <div class="preview-subhead-name"></div>
                </td>
                <td>
                    <div class="preview-subhead-name preview-amount-header">Amount</div>
                </td>
            </tr>
            <tr v-if="!!estimateTo.line_items.lines.Sublet.length && estimateTo.line_items.summary['Sublet-Hrs-SubTotal-Str'] ==''"
                v-for="line in estimateTo.line_items.lines.Sublet">
                <td colspan="5">
                    <div class="preview-item-name">{{line.name}}
                        <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                            Comment: <span>{{line.manual_pdf_comment}}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value">{{line.repairer}}</div>
                </td>
                <td>
                    <div class="preview-item-value"></div>
                </td>
                <td>
                    <div class="preview-item-value"></div>
                </td>
                <td>
                    <div class="preview-item-value preview-amount">{{line.total_str}}</div>
                </td>
            </tr>
            <tr v-if="!!estimateTo.line_items.lines.Sublet.length && estimateTo.line_items.summary['Sublet-Hrs-SubTotal-Str'] == ''" class="nobrd">
                <td colspan="8">
                    <div class="preview-item-name preview-align-right">Subtotal</div>
                </td>
                <td>
                    <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Sublet-Total-SubTotal-Str"]}}</div>
                </td>
            </tr>
            <!--
           Delimiter
          -->
            <tr v-if="!!estimateTo.line_items.lines.Sublet.length" class="nobrd">
                <td colspan="10">
                    <div class="preview-delimiter"></div>
                </td>
            </tr>
            <!--
             Other
            -->
            <tr v-if="!!estimateTo.line_items.lines.Other.length" class="nobrd">
                <td colspan="8">
                    <div class="preview-subhead-name">
                      <template v-if="isVendorBrandingType2">Item</template>
                      <template v-else-if="isVendorBrandingType3">Description</template>
                      <template v-else>Other</template>
                    </div>
                </td>
                <td>
                    <div class="preview-subhead-name preview-amount-header">Amount</div>
                </td>
            </tr>
            <tr v-if="!!estimateTo.line_items.lines.Other.length" v-for="line in estimateTo.line_items.lines.Other">
                <td colspan="8">
                    <div class="preview-item-name">{{line.name}}
                        <div v-if="!isEmpty(line.manual_pdf_comment)" class="font-weight-normal">
                            Comment: <span>{{line.manual_pdf_comment}}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="preview-item-value preview-amount">{{line.total_str}}</div>
                </td>
            </tr>
            <tr class="nobrd" v-if="!!estimateTo.line_items.lines.Other.length">
                <td colspan="8">
                    <div class="preview-item-name preview-align-right">Subtotal</div>
                </td>
                <td>
                    <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Other-Total-SubTotal-Str"]}}</div>
                </td>
            </tr>
            <!--
           Delimiter
          -->
            <tr v-if="!!estimateTo.line_items.lines.Other.length" class="nobrd">
                <td colspan="10">
                    <div class="preview-delimiter"></div>
                </td>
            </tr>
            <!--
            Total
            -->
            <tr v-if="countSubtotal > 1" class="nobrd">
                <td colspan="8">
                    <div class="preview-item-name preview-align-right">Total</div>
                </td>
                <td>
                    <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary["Total_str"]}}</div>
                </td>
            </tr>
            <tr class="nobrd">
                <td colspan="8">
                    <div class="preview-item-name preview-align-right">GST</div>
                </td>
                <td>
                    <div class="preview-item-name preview-amount">{{estimateTo.line_items.summary.GST_str}}</div>
                </td>
            </tr>
            <tr class="nobrd">
                <td colspan="7">
                    <div class="preview-item-name preview-align-right"></div>
                </td>
                <td colspan="">
                    <div class="preview-item-name preview-align-right"
                         style="border-top: 1px solid rgba(27, 30, 56, 0.25) !important;margin-left: 45px; padding-left: 0px;
">Total Inc GST
                    </div>
                </td>
                <td>
                    <div class="preview-item-name preview-amount"
                         style="border-top:1px solid rgba(27,30,56,0.25) !important; position: relative;"
                    >{{estimateTo.line_items.summary.GrandTotal_str}}
                        <div class="preview-total-plus"></div>
                    </div>

                </td>
            </tr>
            <tr class="nobrd">
                <td colspan="10">
                    <div class="preview-terms-delimeter"></div>
                </td>
            </tr>
            <tr class="nobrd">
                <td colspan="10">
                    <div class="preview-terms-header">Terms</div>
                </td>
            </tr>
            <tr class="nobrd">
                <td colspan="10">
                    <div class="preview-terms" v-html="estimateTo.terms"></div>
                </td>
            </tr>

        </table>
    </div>
</template>
<script>
    import {mapGetters} from 'vuex';
    import _ from "lodash";

    export default {
        name: 'estimate-preview',
        props: ['estimateTo', 'isEstimateMethodologyCustom', 'isEstimateMethodologyDollar'],
        data: function () {
            return {};
        },
        computed: {
            ...mapGetters([
                'vendorInfo',
                'isVendorBranding',
                'getVendorBrandingType',
            ]),
            isVendorBrandingType1() {
              return this.isVendorBranding && this.getVendorBrandingType == 'type1'
            },
            isVendorBrandingType3() {
              return this.isVendorBranding && this.getVendorBrandingType == 'type3'
            },
            isVendorBrandingType2() {
              return this.isVendorBranding && this.getVendorBrandingType == 'type2'
            },
            countSubtotal() {
                let countSubtotal = 0;
                if (this.estimateTo && this.estimateTo.line_items && this.estimateTo.line_items.lines) {
                    _.forEach(this.estimateTo.line_items.lines, function (i) {
                        if (i.length > 0) {
                            countSubtotal++;
                        }
                    });
                }
                return countSubtotal;
            }
        },
        methods:{
            isEmpty(val){
                return _.isEmpty(val);
            },
        },
    };
</script>

<style>
.V3 .estimate-preview.branding-type1 {
  font-family: 'Roboto', sans-serif !important;
}

.V3 .estimate-preview.branding-type2 {
  font-family: "Montserrat", sans-serif !important;
}

.V3 .estimate-preview.branding-type3 {
  font-family: "Work Sans", sans-serif !important;
}

.V3 .estimate-preview.branding-type1 .preview-header-title,
.V3 .estimate-preview.branding-type2 .preview-header-title {
  font-weight: 900 !important;
}

.V3 .estimate-preview.branding-type1 .preview-head-name,
.V3 .estimate-preview.branding-type1 .preview-subhead-name,
.V3 .estimate-preview.branding-type2 .preview-head-name,
.V3 .estimate-preview.branding-type2 .preview-subhead-name {
  color: black;
  font-weight: 500 !important;
}

.V3 .estimate-preview.branding-type1 .preview-item-name,
.V3 .estimate-preview.branding-type2 .preview-item-name {
  font-weight: 400 !important;
}

.V3 .estimate-preview.branding-type2 .preview-head-name,
.V3 .estimate-preview.branding-type2 .preview-subhead-name {
  color: white !important;
  background-color: #A9A9A9 !important;
}
.V3 .estimate-preview.branding-type2 .preview-subhead-name span {
  color: white !important;
}

.V3 .estimate-preview.branding-type3 .preview-head-name,
.V3 .estimate-preview.branding-type3 .preview-subhead-name {
  color: white !important;
  background-color: #3a3a3a !important;
}
.V3 .estimate-preview.branding-type3 .preview-subhead-name span {
  color: white !important;
}

</style>
