<template>
  <div class="">
    <div style="margin: 8px 0">
      <button class="update-btn btn btn-secondary" v-if="showEditMenu == false" @click="editBookingCounts" style="margin-left: 8px">
        Edit Count
      </button>
      <button class="update-btn btn btn-secondary" v-if="showEditMenu == true" @click="save" style="margin-left: 8px">
        Save
      </button>
      <button class="update-btn btn btn-secondary" v-if="showEditMenu == true" @click="bookingCounts.push(null)" style="margin-left: 8px">
        Create Tracker
      </button>
      <button class="update-btn btn btn-secondary" v-if="showEditMenu == true" @click="cancelBookingCounts" style="margin-left: 15px">
        Cancel
      </button>
    </div>
    <div class="cards-all-snapshot-main-total d-flex">
      <div class="cards-all-snapshot-main-total_item">
        <div class="cards-all-snapshot-main-total_item-border">
          <div class="cards-all-snapshot-main-total_item-sum cards-all-snapshot-main-total_item-sum-bold">
            {{computedEventsFilteredToday.length}}
          </div>
          <div class="cards-all-snapshot-main-total_item-name">
            Onsite Estimate Bookings (Today)
          </div>
        </div>
      </div>
      <div v-if="!showEditMenu" class="cards-all-snapshot-main-total_item" v-for="(itm, ind) in bookingCounts" :key="ind">
        <div class="cards-all-snapshot-main-total_item-border">
          <div class="cards-all-snapshot-main-total_item-sum">
            {{getCountByStatusId(itm)}}
          </div>
          <div class="cards-all-snapshot-main-total_item-name">
            {{getNameById(itm)}}
          </div>
        </div>
      </div>
      <div v-if="showEditMenu == true" class="cards-all-snapshot-main-total_item"  v-for="(itm, ind) in bookingCounts" :key="ind">

        <div class="cards-all-snapshot-main-total_item-border" style="padding: 8px 12px">
          <div class="cards-all-snapshot-main-total_item-sum cards-all-snapshot-main-total_item-sum-bold"  style="float: right;">

            <div class=""  @focusout="closePopupMenu" tabindex="0">
              <i class='bx bx-dots-vertical-rounded' style="color: grey"  @click="showPopupMenu(itm + ind)" :id="itm + ind"></i>
              <ul v-if="showMenuItem == itm + ind"
                  class="card-menu-popup"
                  style="position: absolute; transform: translate(12px, -8px);"
                  :class="{'card-menu-popup-open-left': isRevertMenuCheck(itm + ind)}">
                <li class="parent" v-if="!itm && computedStatuses.length">
                  <div class="title">
                    <i v-if="isRevertMenuCheck(itm + ind)"
                       class="bx bx-chevron-left"></i>
                    Add Status
                    <i v-if="!isRevertMenuCheck(itm + ind)"
                       class="bx bx-chevron-right"></i>
                  </div>
                  <div class="sub-menu">
                    <ul class="sub-menu-inner">
                      <li v-for="status in computedStatuses" :key="status.ce_status_id" @click="addBookingCounts(status.ce_status_id, ind)">{{ status.name }}</li>
                    </ul>
                  </div>
                </li>
                <li  class="parent" :class="{'reverse-label': isRevertMenuCheck(itm + ind)}" @click="removeTrackers(itm, ind)">
                  <div class="title"
                  >
                    Remove Status
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div style="font-weight: 900; font-size: 15px; text-align: center; margin-top: 20%;">
             {{getNameById(itm)}}
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import Axios from "axios";
import _ from "lodash";
import dayjs from "dayjs";

export default {
  name: "booking-counts",
  data: function () {
    return {
      bookingCounts: [],
      addCounts: [],
      bookingCountsBeforeEdit: [],
      removeBookingCounts: [],
      showMenuItem: null,
      showEditMenu: false,
    }
  },
  mounted() {
    let v = _.cloneDeep(this.vendorInfo)
    this.bookingCounts = v.bookingCounts ? v.bookingCounts : []
  },
  watch: {
    vendorInfo: {
      handler(v) {
        this.bookingCounts = v.bookingCounts ? v.bookingCounts : []
      },
      deep: true
    }
  },
  methods: {
    save() {
      this.showEditMenu = false

      NProgress.start();
      let addBooking = _.filter(this.addCounts, (c) => {
        return !_.includes(this.bookingCountsBeforeEdit, (c))
      })

      Axios.post('/fe/booking-trackers', {addBookingCount: _.filter(addBooking, (id) =>  {return Number(id)}), removeBookingCount: _.filter(this.removeBookingCounts, (id) =>  {return Number(id)})}
      ).then(res => {
        if (res.data._status) {
          this.bookingCounts = _.filter(this.bookingCounts, (id) =>  {return Number(id)})
          this.isEdit = false
          this.$store.dispatch('loadVendorInfo');
          this.addCounts = [];
          this.removeBookingCounts = []
        }
      }).catch(e => {
        console.log(e);
      })
          .finally(()=>{
            NProgress.done();
          })
    },
    removeTrackers(id, index) {
      this.closePopupMenu()
      if (_.includes(this.addCounts, id)) {
        if (id == null) {
          let nInd = _.find(this.addCounts, (i) => {return i == null})
          if (nInd !== -1) {
            this.addCounts.splice(nInd, 1)
          }
        } else {
          this.addCounts = _.filter(this.addCounts, (rId) => {
            return Number(rId) !== Number(id)
          })
        }
      }
      if (_.includes(this.bookingCounts, id)) {
        if (id == null) {
          this.bookingCounts.splice(index, 1)
        } else {
          this.bookingCounts = _.filter(this.bookingCounts, (rId) => {
            return Number(rId) !== Number(id)
          })
        }
      }
      this.removeBookingCounts.push(id)
    },
    editBookingCounts() {
      this.showEditMenu = true
      this.bookingCountsBeforeEdit = _.cloneDeep(this.bookingCounts)
    },
    addBookingCounts(id, index) {
      this.closePopupMenu();
      if (_.includes(this.removeBookingCounts, id)) {
        this.removeBookingCounts = _.filter(this.removeBookingCounts, (rId) => {
          return Number(rId) !== Number(id)
        })
      }
      this.addCounts.push(id)
      if (index == -1) {
        this.bookingCounts.push(id)
      } else {
        this.bookingCounts.splice(index, 1, id)
      }
    },
    showPopupMenu(id) {
      this.showMenuItem = id
    },
    closePopupMenu() {
      this.showMenuItem = null
    },
    cancelBookingCounts() {
      this.showEditMenu = false
      this.addCounts = []
      this.removeBookingCounts = []
      this.bookingCounts = this.bookingCountsBeforeEdit
    },
    getNameById(d) {
      if (!d) {
        return  ''
      }
      return _.find(this.ceStatuses, (c) => {
        return Number(d) == Number(c.ce_status_id)
      })?.name
    },
    isRevertMenuCheck(id) {
      let el = document.getElementById(id)?.getBoundingClientRect();
      if (!el || (window.innerWidth - el.right) <= 330) {
        return true
      }
      return false
    },
    getCountByStatusId(id) {
      return _.filter(this.computedEvents, (e) => {
        return Number(e?.card?.statusId) == Number(id)
      })?.length
    },

    toTimestamp(date) {
      if (typeof(date) !== 'string') {
        return date
      }
      // "2022-07-25 09:00"
      if (date.includes(':')) {
        let [date1, date2] = date.split(' ');
        let [year, month, day] = date1.split('-');
        let [hours, minutes] = date2.split(':');

        let datum = new Date(year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':00');
        return datum.getTime();

      } else {
        let [year, month, day] = date.split('-');
        let datum = new Date(year + '-' + month + '-' + day + 'T00:00:00');
        return datum.getTime();
      }
    },


  },
  computed: {
    ...mapGetters({
      vendorInfo: 'vendorInfo',
      ceStatuses: 'ceStatuses',
      getBookingItems: 'board/getBookingItems',
      filter: 'board/getBookingFilter',
    }),
    computedEvents() {
      let events = _.filter(this.getBookingItems, (e) => {
        return e.type == 'on-site'
      })
      return _.map(events, (e) => {
        e.start = this.toTimestamp(e.dateStart + ' ' + e.timeStart);
        e.end = this.toTimestamp(e.dateEnd + ' ' + e.timeEnd);
        return e
      });
    },
    computedEventsFiltered() {
      return _.filter(this.computedEvents, (e) => {
        let eventS = e.start;
        let eventE = e.end;
        let filterS = this.computedFilterTimeStamp.start;
        let filterE = this.computedFilterTimeStamp.end;
        return (eventS >= filterS && eventS <= filterE) ||  (eventE >= filterS && eventE <= filterE)
      })
    },

    computedEventsFilteredToday() {
      let start2 = dayjs().format('YYYY-MM-DD') + ' 00:00';
      let end2 = dayjs().format('YYYY-MM-DD') + ' 23:59:59';
      let start = new Date(start2).getTime()
      let end = new Date(end2).getTime()

      return _.filter(this.computedEvents, (e) => {
        let eventS = e.start;
        let eventE = e.end;
        let filterS = start;
        let filterE = end;
        return (eventS >= filterS && eventS <= filterE) ||  (eventE >= filterS && eventE <= filterE)
      })
    },

    computedFilterTimeStamp() {
      let r = {
        start: null,
        end: null,
        start2: null,
        end2: null
      }
      if (this.filter == 'Month') {
        r.start2 = dayjs().startOf('month').format('YYYY-MM-DD') + ' 00:00'
        r.end2 = dayjs().endOf('month').format('YYYY-MM-DD') + ' 23:59:59'
      } else if (this.filter == 'Last Week') {
        let now = new Date();
        r.end2 = dayjs(new Date(now.setDate(now.getDate() - now.getDay()))).format('YYYY-MM-DD') + ' 23:59:59';
        r.start2 = dayjs(new Date(now.setDate(now.getDate() - 6))).format('YYYY-MM-DD') + ' 00:00';
      } else if (this.filter == 'Day') {
        r.start2 = dayjs().format('YYYY-MM-DD') + ' 00:00';
        r.end2 = dayjs().format('YYYY-MM-DD') + ' 23:59:59';
      } else if (this.filter == 'Week') {
        r.start2 = dayjs().isoWeekday(1).format('YYYY-MM-DD') + ' 00:00';
        r.end2 = dayjs().isoWeekday(7).format('YYYY-MM-DD') + ' 23:59:59'
      }
      r.start = new Date(r.start2).getTime()
      r.end = new Date(r.end2).getTime()
      return r
    },

    computedStatuses() {
      return _.filter(this.ceStatuses, (s) => {
        return !_.find(this.bookingCounts, (i) => {
          return Number(i) == Number(s.ce_status_id)
        })
      })
    }

  },
};
</script>


<style scoped>
.card-menu-popup-open-left {
  transform: translate(-121px, -8px) !important;
}
.card-menu-popup-open-left .sub-menu {
  left: -143px !important;
}

.reverse-label {
  display: flex;
  justify-content: end;
}

.cards-all-snapshot-main-total_item{
  text-align: center;
  flex: 20%;
  padding: 5px;
  max-width: 20%;
}
.cards-all-snapshot-main-total_item-border{
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  padding: 20px 20px;
  height: 100%;
}
.cards-all-snapshot-main-total_item:first-child{
  margin-left: 0;
}
.cards-all-snapshot-main-total_item:last-child{
  margin-right: 0;
}
.cards-all-snapshot-main-total_item-sum{
  font-weight: 700;
  font-size: 23px;
}
.cards-all-snapshot-main-total_item-name{
  margin-top: 10px;
  font-weight: 700;
  font-size: 15px;
}
.cards-all-snapshot-main-total_item-sum-bold{
  font-size: 25px;
  font-weight: 900;
}



@media screen and (max-width: 1100px) {

  .cards-all-snapshot-main-total_item{
    flex: 33.333%;
    max-width: 30%;
  }
}

</style>
