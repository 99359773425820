<template>
  <div class="">
    <div style="overflow-x: auto;" class="pagination-block-bottom">
      <b-table
          class="rs-scroll rs-scroll--y noselect"
          responsive
          hover
          :items="cards"
          ref="cardsAll"
          :fields="fields"
          :filter="filter"
          :filter-function="filterForTable"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @sort-changed="v => $emit('setSortByAndSortDesc', v)"
          :current-page="currentPage"
          :per-page="getterPerPage"
          @filtered="onCardsFiltered"
          @row-clicked="onCardsRowClick"
          @row-contextmenu="openMenuCards2"
      >
        <template v-slot:cell(insurance)="data">
          <div v-if="data.item.insurance" :style="getInsurerNameStyle(data.item.insurerCardColorId)">
            {{ data.item.insurance }}
          </div>
        </template>
        <template v-slot:cell(assignedTo)="data">
          <div>
            {{ data.item.assignedTo && data.item.assignedTo.id ? data.item.assignedTo.name : '&nbsp' }}
          </div>
        </template>
        <template v-slot:cell(notifications)="data">
          <notification-cell class="row no-gutters text-center" :item="data.item"
                             :name="type + `-${data.item.card_id}`"/>
        </template>
        <template v-slot:cell(priority)="data">
                                <span v-if="data.item.priority === 'High'" style="color: #FF5E5E">
                                  High
                                </span>
          <span v-else-if="data.item.priority === 'Medium'" style="color: #FFDB00">
                                  Medium
                                </span>
          <span v-else-if="data.item.priority === 'Low'" style="color: #18D06B">
                                  Low
                                </span>
          <span v-else-if="data.item.priority === 'OnHold'" style="color: #419abe">
                                  On Hold
                                </span>

        </template>
        <template v-slot:cell(status_name)="data">
          <p
              style="margin-bottom:3px;font-weight:bold;padding-left:20px;"
          >{{ data.item.status_name }}</p>
          <p style="margin-bottom:3px;font-size:11px;padding-left:20px;">
            <!--                                    <span>{{ data.item.ceStatus }} to {{ ceStatuses.length }}</span>-->
            <span>{{ statusPosition(data.item) }} to {{ ceStatuses.length }}</span>
          </p>
          <div class="progress">
            <!--                                    <div class="bar" :style="{'width':`${data.item.ceStatusPercentage}%`}"></div>-->
            <div class="bar" :style="{'width':`${statusPositionByPercentage(data.item)}%`}"></div>
          </div>
        </template>
      </b-table>
    </div>


    <block-pagination
        :role="'cards'"
        :count="cardsGetter.length"
        :countFiltered="filter ? cardsFilteredCount : cards.length"
        :currentPage="currentPage"
        @changedCurrentPage="value => $emit('setCurrentPage', value)"
        :perPage="getterPerPage"
        :perPageForMultipleTables="getterPerPage"
        @changedPerPage="value => $store.commit('card/setPerPage', value)"
    >
    </block-pagination>


    <vue-context ref="rightClickMenuForCards" @open="contextOpen" @close="contextClose">
      <ul slot-scope="scope" ref="contextMenuForCards">
        <li>
          Card Mode
          <i class="icon-right bx bx-chevron-right"></i>
          <ul type="none" class="subContext assign-to-context">
            <li v-if="scope && scope.data && scope.data.item && scope.data.item.state != 0"
                @click="onRightClickByMenu(0, scope.data)"
            >
              Mark As Open
            </li>
            <li v-if="scope && scope.data && scope.data.item && scope.data.item.state != 1"
                @click="onRightClickByMenu(1, scope.data)"
            >
              Mark As Active
            </li>
            <li v-if="scope && scope.data && scope.data.item && scope.data.item.state != 3"
                @click="onRightClickByMenu(3, scope.data)"
            >
              Mark As Closed
            </li>
            <li v-if="scope && scope.data && scope.data.item && scope.data.item.activity
                                && (
                                    (scope.data.item.activity.Assignment && scope.data.item.activity.Assignment.from)
                                    || (scope.data.item.activity.External && scope.data.item.activity.External.date)
                                    || (scope.data.item.activity.Email && scope.data.item.activity.Email.date)
                                    || (scope.data.item.activity.Message && scope.data.item.activity.Message.from)
                                )"
                @click="markAsRead(scope.data.item.card_id)"
            >
              Mark As Read
            </li>
          </ul>

        </li>

        <li>
          Open
          <i class="icon-right bx bx-chevron-right"></i>
          <ul type="none" class="subContext assign-to-context">
            <li @click="openCardInNewTab(scope.data.item.card_id)">
              in New Tab
            </li>
            <li @click="openCardInNewWindow(scope.data.item.card_id)">
              in New Window
            </li>
          </ul>

        </li>

        <li>
          Add to
          <i class="icon-right bx bx-chevron-right"></i>
          <ul type="none" class="subContext assign-to-context">
            <li @click="addToVehicleInBoard(scope.data.item.card_id, scope.data.item.card_number)">
              Vehicle In Board
            </li>
            <li @click="addToDepartureBoard(scope.data.item.card_id, scope.data.item.card_number)">
              Departure Board
            </li>
          </ul>

        </li>

        <li v-if="false" @click="acceptORMJob">
          Accept ORM JOB
        </li>

        <li v-if="false" @click="openDeclineModal">
          Decline ORM Job
        </li>

        <li>
          Set Priority
          <i class="icon-right bx bx-chevron-right"></i>
          <ul type="none" class="subContext assign-to-context">
            <li @click="setCardPriority(scope.data.item.card_id, 'High')" class="d-flex">
              <span class="status-color high-color" style="margin-right: 5px; margin-top: 1px"></span>High Priority
            </li>
            <li @click="setCardPriority(scope.data.item.card_id, 'Medium')" class="d-flex">
              <span class="status-color medium-color" style="margin-right: 5px; margin-top: 1px"></span>Medium Priority
            </li>
            <li @click="setCardPriority(scope.data.item.card_id, 'Low')" class="d-flex">
              <span class="status-color low-color" style="margin-right: 5px; margin-top: 1px"></span>Low Priority
            </li>
            <li @click="setCardPriority(scope.data.item.card_id, 'OnHold')" class="d-flex">
              <span class="status-color on-hold-color" style="margin-right: 5px; margin-top: 1px"></span> On Hold
            </li>
            <li v-if="scope.data && scope.data.item && isHasPriority(scope.data.item.priority)"
                @click="setCardPriority(scope.data.item.card_id, 'Undo')" class="d-flex">
              <span class="status-color undo-priority-color" style="margin-right: 5px; margin-top: 1px"></span> Undo
              Priority
            </li>

          </ul>

        </li>

        <li>
          Assign to
          <i class="icon-right bx bx-chevron-right"></i>
          <ul type="none" class="subContext assign-to-context">
            <input class="input-assign-to" type="text" placeholder="Search" v-model="filterAssignTo">
            <li @click="assignedToPerson(scope.data, user.id)" v-for="user in computedActiveUsers">{{ user.name }}
            </li>
          </ul>

        </li>

        <li v-if="scope && scope.data && scope.data.item "
            @click='copyCardModal(scope.data.item.card_id, scope.data.item.card_number)'
        >
          Copy Card
        </li>
      </ul>
    </vue-context>

    <modal-wizard
        ref="markAsModal"
        cancelButtonText="Close"
        finishButtonText="Ok"
        @on-complete="completeMarkAs"
    >
      <modal-wizard-tab
          :title="modal.title"
          description
          title-icon-class="ti-align-right"
          :tabData="modal"
      >
        <template slot-scope="props">
          <div>{{ props.text }}</div>
          <div class="mark-as">
            <div class="row">
              <div class="col-md-4 estimate-number">Estimate Number:</div>
              <div class="col-md-5">{{ props.estimate_number }}</div>
            </div>
            <div class="row" v-if="props.supplement_numbers != ''">
              <div class="col-md-4 estimate-number">Supplement Number(s):</div>
              <div class="col-md-5">{{ props.supplement_numbers }}</div>
            </div>
          </div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>

    <modal-wizard
        id="declineORMJob"
        ref="declineORMJob"
        cancelButtonText="Cancel"
        finishButtonText="Ok"
        :finishButtonTooltip="isDisabledModalFinishButton ? 'Please fill in all fields' : ''"
        :isDisabledFinishButton="isDisabledModalFinishButton"
        @on-close="locationForDecline = ''; reasonForDeclineORM = '';"
        @on-complete="declineORMJob">
      <modal-wizard-tab title="Decline ORM Job" description="" title-icon-class="ti-align-right" class="pr-2">
        <template slot-scope="props">
          <div class="pt-2">Are you sure you want Decline this Job?</div>
          <div class="form-group row">
            <label class="col-form-label"></label>
            <div class="col-12">
              <textarea v-model="reasonForDeclineORM" style="height: 67px; resize: none" class="form-control"
                        placeholder="Please add a reason for declining the repair"/>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label"></label>
            <div class="col-12">
              <multiselect
                  :options="['With me the Repairer', 'Returned to the Customer', 'RMC']"
                  :showLabels="false"
                  v-model="locationForDecline"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Please confirm the current location of the vehicle">
              </multiselect>
            </div>
          </div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>

    <modal-wizard ref="copyCardModal" cancelButtonText="Cancel" finishButtonText="Create"
                  @on-complete="completeCopyCard" class="copy-card-modal">
      <modal-wizard-tab title="Copy Card" description="" title-icon-class="ti-align-right">
        <div class="copy-card-modal-text">Do you want to Copy this Card {{ cardNumberForCopy }} and all of its details?
        </div>
        <div class="copy-card-modal-block">
          <div class="copy-card-modal-block-top">
            <div class="col-md-6">
              <span class="">Create a New Card & Estimate</span>
            </div>
            <div class="col-md-4">
              <label class="form-check-inline checkbox checkbox-v">
                <input v-model="copyCard.isNew" class="form-check-input" type="checkbox"/>
                <span class="icon"><i class='bx bx-check'></i></span>
              </label>
            </div>

          </div>
          <span class="copy-card-modal-text-middle">or</span>
          <div class="copy-card-modal-block-top">
            <div class="col-md-6">
              <span class="">Select an existing Card & Estimate to Copy {{ cardNumberForCopy }} into</span>
            </div>
            <div class="col-md-5">
              <multiselect
                  v-model="copyCard.copyToCard"
                  :options="computedCardsForCopyCard"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Select Card Number"
                  track-by="card_id"
                  label="card_number"
                  :disabled="copyCard.isNew"
              />
            </div>
          </div>
          <span class="copy-card-modal-text-middle">Optional</span>
          <div class="copy-card-modal-block-top">
            <div class="col-md-6 copy-card-modal-block copy-card-text-align">
              <span class="">Copy Images</span>
            </div>
            <div class="col-md-4 copy-card-modal-block">
              <label class="form-check-inline checkbox checkbox-v">
                <input v-model="copyCard.isCopyImages" class="form-check-input" type="checkbox"/>
                <span class="icon"><i class='bx bx-check'></i></span>
              </label>
            </div>
          </div>
          <div class="copy-card-modal-block-top">
            <div class="col-md-6 copy-card-modal-block copy-card-text-align">
              <span class="">Copy Files</span>
            </div>
            <div class="col-md-4 copy-card-modal-block">
              <label class="form-check-inline checkbox checkbox-v">
                <input v-model="copyCard.isCopyFiles" class="form-check-input" type="checkbox"/>
                <span class="icon"><i class='bx bx-check'></i></span>
              </label>
            </div>
          </div>
        </div>
      </modal-wizard-tab>
    </modal-wizard>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import Multiselect from 'vue-multiselect'
import { VueContext } from 'vue-context'
import NotificationCell from '../../notification-cell'
import CardsMixins from '../cards-mixins'
import BlockPagination from '../../utility/block-pagination'

export default {
  name: 'card-table',
  mixins: [CardsMixins],
  components: {
    VueContext,
    NotificationCell,
    Multiselect,
    BlockPagination,
  },
  props: {
    cards: {
      type: Array,
      default () {
        return []
      }
    },
    cardsGetter: {
      type: Array,
      default () {
        return []
      }
    },
    sortBy: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      cardsFilteredCount: 0,
      contextOpenEvent: null,
      filterAssignTo: '',
      fields: [
        {
          label: 'Number',
          key: 'card_number',
          sortable: true,
          tdClass: 'text-center clickable cards-table-number',
          thClass: 'text-center bg-navyblue  cards-table-number',
        },
        {
          label: 'Vehicle Owner',
          key: 'customer_name',
          sortable: true,
          tdClass: 'clickable cards-table-vehicle-owner',
          thClass: 'bg-navyblue  cards-table-vehicle-owner',
        },
        {
          label: 'Rego',
          key: 'rego_number',
          sortable: true,
          tdClass: 'text-center clickable cards-table-rego',
          thClass: 'text-center bg-navyblue cards-table-rego',
        },
        {
          label: 'Make',
          key: 'make',
          sortable: true,
          tdClass: 'text-center clickable cards-table-make',
          thClass: 'text-center bg-navyblue cards-table-make',
        },
        {
          label: 'Model',
          key: 'model',
          sortable: true,
          tdClass: 'text-center clickable cards-table-model',
          thClass: 'text-center bg-navyblue cards-table-model',
        },
        {
          label: 'Colour',
          key: 'colour',
          sortable: true,
          tdClass: 'text-center clickable cards-table-colour',
          thClass: 'text-center bg-navyblue cards-table-colour',
        },
        {
          label: 'Insurer',
          key: 'insurance',
          sortable: true,
          tdClass: 'text-center clickable cards-table-insurer',
          thClass: 'text-center bg-navyblue cards-table-insurer',
        },
        {
          label: 'Date Created',
          sortable: true,
          key: 'created_on',
          sortByFormatted: (value, key, item) => {
            return item.sortCreatedDate
          },
          tdClass: 'text-center clickable  cards-table-created-on',
          thClass: 'text-center bg-navyblue  cards-table-created-on',
        },
        {
          label: 'Assigned',
          key: 'assignedTo',
          sortable: true,
          sortByFormatted: (value, key, item) => {
            if (item.assignedTo && item.assignedTo.name && item.assignedTo.id) {
              return item.assignedTo.name
            }
          },
          tdClass: 'text-center clickable  cards-table-assigned-to',
          thClass: 'text-center bg-navyblue  cards-table-assigned-to',
        },
        {
          label: 'Priority',
          key: 'priority',
          sortable: true,
          tdClass: 'text-center clickable  cards-table-assigned-to',
          thClass: 'text-center bg-navyblue  cards-table-assigned-to',
        },
        {
          label: 'Notifications',
          key: 'notifications',
          sortable: true,
          tdClass: 'clickable  cards-table-notifications',
          thClass: 'text-center bg-navyblue cards-table-notifications',
        },
        {
          label: 'Status',
          key: 'status_name',
          sortable: true,
          tdClass: 'text-center clickable  cards-table-status',
          thClass: 'text-center bg-navyblue cards-table-status',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      filter: 'cardFilter',
      getterPerPage: 'card/getPerPage',
      getterVisibleUsers: 'visibleUsers',
      getterInsurerCardColor: 'getInsurerCardColor',
      ceStatuses: 'ceStatuses',
    }),
    computedActiveUsers () {
      if (!this.filterAssignTo || this.filterAssignTo == '') {
        let users = this.getterVisibleUsers.slice()
        users.unshift({
          id: null,
          name: 'None',
          groupId: null,
          group_id: null,
          isActive: true,
          isVisible: true,
          lastName: null,
          firstName: null,
          role: null,
        })
        return users
      }
      let self = this
      let filterUsers = _.filter(this.getterVisibleUsers, function (user) {
        let search = []
        search.push(_.toLower(user.name))
        return !!(search.join(',').indexOf(_.toLower(self.filterAssignTo)) > -1)
      })
      return filterUsers
    },
  },
  methods: {
    getInsurerNameStyle (id) {
      if (!id) {
        return null
      }
      let data = this.getterInsurerCardColor?.[id]
      if (data) {
        return {
          color: data.color,
          backgroundColor: data.background,
          fontWeight: 800,
          padding: '3px 7px',
          borderRadius: '5px',
          textAlign: 'center',
          display: 'inline-block'
        }
      }
      return null
    },
    statusPositionByPercentage (item) {
      let index = this.statusPosition(item)
      return ((index / this.ceStatuses.length) * 100)
    },
    statusPosition (item) {
      let positionStatus =
          this.ceStatuses.findIndex(
              (value) => Number(value.ce_status_id) === Number(item.card_status)
          ) + 1
      return positionStatus
    },
    isHasPriority (priority) {
      if (priority === 'Low' || priority === 'Medium' || priority === 'High' || priority === 'OnHold') {
        return true
      }
      return false
    },
    contextOpen: function (event, w, e) {
      this.contextOpenEvent = event
    },
    contextClose: function () {
      let event = this.contextOpenEvent
      if (event) {
        let row = event.target.parentElement
        let rows = row.parentElement.rows
        for (let i = 0; i < rows.length; i++) {
          let elem = rows[i]
          elem.style.backgroundColor = ''
        }
      }
    },
    openMenuCards2: function (item, index, event) {
      event.preventDefault()
      let row = event.target.parentElement
      let rows = row.parentElement.rows
      for (let i = 0; i < rows.length; i++) {
        let elem = rows[i]
        elem.style.backgroundColor = ''
      }
      row.style.backgroundColor = '#F3F3F3'
      this.openMenuCards(event, index, item)
    },

    openMenuCards: function (event, index, item) {
      if (!this.isStatusActive) {
        return
      }
      let vm = this
      this.$refs.rightClickMenuForCards.open(event, {
        item: item,
        index: index,
      })
      setTimeout(() => {
        [].slice
            .call(this.$refs.contextMenuForCards.children)
            .forEach((elem) => {
              if (elem.children.length && elem.querySelector('.subContext')) {
                elem.classList.add('hasSubContext')
                elem.addEventListener('click', (e) => {
                  e.stopPropagation()
                })
                elem.addEventListener('mouseenter', () => {
                  if (
                      window.innerWidth <
                      elem.getBoundingClientRect().right +
                      elem.querySelector('.subContext').getBoundingClientRect()
                          .width
                  ) {
                    elem
                        .querySelector('.subContext')
                        .classList.add('position_sub_context_left')
                  } else
                    elem
                        .querySelector('.subContext')
                        .classList.remove('position_sub_context_left')
                })
              } else elem.classList.remove('hasSubContext')
            })
      }, 0)
    },

    onCardsRowClick: function (item, index, event) {
      this.$router.push({
        name: 'CardsEdit',
        params: {
          card_id: item.card_id,
          filters: this.$route.params.filters ? this.$route.params.filters : this.$route.query.filters,
          trackerFilter: this.$route.params.trackerFilter ? this.$route.params.trackerFilter : this.$route.query.trackerFilter
        }
      })
    },

    onCardsFiltered (fi) {
      this.cardsFilteredCount = fi.length
      this.$emit('setCurrentPage', 1)
    },

    filterForTable (row, filter) {
      if (filter == this.$route.params.filter) {
        return !!_.find(this.$route.params.items, (itm) => {
          return Number(itm) == Number(row.card_id)
        })
      }

      let filtersFromRoute = []
      if (this.$route.params && !_.isEmpty(this.$route.params.filters)) {
        filtersFromRoute = this.$route.params.filters
      }
      if (this.$route.query && !_.isEmpty(this.$route.query.filters)) {
        filtersFromRoute = _.isArray(this.$route.query.filters) ? this.$route.query.filters : [this.$route.query.filters]
      }

      if ((filter == this.$route.params.trackerFilter || filter == this.$route.query.trackerFilter) && !_.isEmpty(filtersFromRoute)) {
        return !!_.find(filtersFromRoute, (id) => Number(id) == Number(row.card_id))
      }
      let formattedFilter = _.trim(_.toLower(filter))
      if (row.card_number && row.card_number.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.customer_name && row.customer_name.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.rego_number && row.rego_number.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (this.isCanSearchBySomeFields && row.insurance && row.insurance.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (this.isCanSearchBySomeFields && row.estimateNumber && row.estimateNumber.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.make && row.make.toLowerCase().includes(formattedFilter)) {
        return true
      } else if (row.model && row.model.toLowerCase().includes(formattedFilter)) {
        return true
      }
      return false
    },
  }
}
</script>

<style scoped>
.input-assign-to {
  border: 1px solid buttonface;
  padding: 2px 7px;
  margin: 5px 7px;
  width: 90%;
  border-radius: 5px;
}

.assign-to-context {
  height: auto;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.progress {
  margin: 0px auto;
  padding: 0;
  width: 150px;
  height: 7px;
  overflow: hidden;
  background: #e3e4e7;
  border-radius: 6px;
}

.bar {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  background: linear-gradient(135deg, #7267E7 0%, #6259EF 49.12%, #4E45EE 100%);
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

tbody tr {
  cursor: pointer;
}

.hasSubContext:hover .subContext {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.position_sub_context_left {
  right: calc(100% + 1px);
  left: auto;
}

.hasSubContext {
  position: relative;
  padding-right: 5px !important;
}

.icon-right {
  font-size: 21px !important;
  transform: translate(100%, -3px) !important;
}

.subContext {
  position: absolute;
  left: calc(100% + 1px);
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
  min-width: 100%;
  width: auto;

  top: 0;
  transition: 0.4s;
  visibility: hidden;
  opacity: 0;
  right: auto;
  transform: translateY(-10px);
}

.hasSubContext ul > li {
  height: auto;
}

@media screen and (max-width: 767px) {
  .hasSubContext:hover:after {
    transform: rotate(90deg);
  }

  .subContext {
    left: 0 !important;
    right: auto !important;
    top: 100% !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .search-filter {
    padding-left: 15px !important;
    padding-right: 15px;
  }
}
</style>
